<template>
  <div>
    <reader-footer-toolbar></reader-footer-toolbar>

  </div>
</template>

<script>

import ReaderToolbar from "./ReaderFooterToolbar.vue"

export default {
  name: "reader-footer",
  components: {
    'reader-footer-toolbar': ReaderToolbar,
  }
}
</script>

<style scoped>

</style>