<template>
  <div v-if="sentenceanalysis" class="no-outline">
    <!--CONTAINTER-->
    <div id="reader__workspace__analysis--sentence">
      <div class="reader-analysis">
        <div class="reader__header--close flex-group-sb not-responsive text-2xl bg-very-light-grey">
          <div class="p-3 flex flex-row justify-start items-center">
            <hermeneus-icon width="28" height="28" name="hi-caduceus" color="hi-color-green-light" class="mr-2"></hermeneus-icon>
            <div>Satzanalyse</div>
          </div>
          <button @click="close" class="reader__header--close-button p-3"><i class="hi hi-close"></i></button>
        </div>
        <div v-if="Tool_Syntax_Active">

        <dependency-tree-analysis :sentenceanalysis="sentenceanalysis" ></dependency-tree-analysis>
        </div>

        <div v-else class="flex flex-row justify-between">

<!--          <button

              :class="{'text-grey-300 hover:text-grey-500': isNotFirstSentence, 'text-grey-100': !isNotFirstSentence}"
              @click="openPreviousSentence" class="w-12  flex flex-row justify-center items-center p-4  "><i class="fas fa-caret-left text-5xl"></i></button>-->
          <div

              id="reader__workspace__analysis--sentence_container"
              ref="sentence_html"
              v-html="this.sentenceanalysis"
              :class="[active_farben, {
                            'presenter-no-gaps': Tool_Steps_No_Gaps,
                            'show-subj-pred': Tool_SubjPred_Active,
                            'subj-pred-highlight': Tool_SubjPred_Highlight,
                            // Übergeordnete CSS-Klasse, die nur vorhanden ist, wenn die Markierungen im Text gezeigt werden sollen
                            'show-highlight1-sentence': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight1')&& (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight1').showSentence,
                            'show-highlight2a-sentence': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight2a')&& (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight2a').showSentence,
                            'show-highlight2b-sentence': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight2b')&& (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight2b').showSentence,
                            'show-highlight2c-sentence': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight2c')&& (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight2c').showSentence,
                            'show-highlight2d-sentence': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight2d')&& (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight2d').showSentence,
                            'show-highlight3-sentence': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight3')&& (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight3').showSentence,
                            'show-highlight4-sentence': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight4')&& (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight4').showSentence,
                            }]"
              class="hermeneus-text hermeneus-analysis w-full h100 p-8 my-8 noselect"></div>
<!--          <button
              :class="{'text-grey-300 hover:text-grey-500': isNotLastSentence, 'text-grey-100': !isNotLastSentence}"

              @click="openNextSentence" class="w-12 text-grey-300 flex flex-row justify-center items-center p-4  hover:text-grey-500"><i class="fas fa-caret-right text-5xl"></i></button>-->

        </div>
      </div>
      <transition name="slideY">
        <reader-sentence-viewer-answer v-if="this.Tool_Answers_Active && this.Answer" :answer="Answer" class="p-8"></reader-sentence-viewer-answer>
      </transition>

      <div class="reader__workspace__analysis--bottom-container h-full bg-very-light-grey">

        <!--SUB-LINEAM WORD-ANALYSIS and ANNOTATIONS-->
        <reader-sentence-viewer-sub-lineam ref="sentence_sublineam"></reader-sentence-viewer-sub-lineam>
        <!--TOOLBAR-->
        <reader-sentence-viewer-toolbar
            ref="sentence_toolbar"
            :hasSteps="hasSteps"
            :hasAnswers="hasAnswers"
            :hasFarben="hasFarben"
            :hasHighlights="hasHighlights"
            :hasSubjPred="hasSubjPred"

            class="reader__sentence-viewer__bottom-grey overflow-visible bg-very-light-grey"></reader-sentence-viewer-toolbar>
        <!--INFOBAR-->
        <reader-sentence-viewer-infobar ref="sentence_infobar" :info="Info" class="reader__sentence-viewer__bottom-grey bg-very-light-grey"></reader-sentence-viewer-infobar>
      </div>
    </div>
    <!--Darkens background-->
    <div class="reader__analysis__background"></div>
  </div>
</template>

<script>
import {EventBus} from '../../../../hermeneus.eventbus/eventbus'
import ReaderSentenceViewerSubLineam from "./ReaderSentenceViewerSubLineam.vue"
import ReaderSentenceViewerToolbar from "./ReaderSentenceViewerToolbar.vue"
import ReaderSentenceViewerInfobar from "./ReaderSentenceViewerInfobar.vue"
import Reader from "../../reader.class_reader";
import {wait} from "../../../../helpers/functions_helpers";
import ReaderSentenceViewerAnswer from "./ReaderSentenceViewerAnswer.vue"


export default {
  name: "reader-sentence-viewer",
  components: {


    'reader-sentence-viewer-sub-lineam': ReaderSentenceViewerSubLineam,
    'reader-sentence-viewer-toolbar': ReaderSentenceViewerToolbar,
    'reader-sentence-viewer-answer': ReaderSentenceViewerAnswer,
    'reader-sentence-viewer-infobar': ReaderSentenceViewerInfobar
  },
  props: ['sentenceanalysis'],
  data: () => {
    return {
      Info: '',
      Steps: {},
      SubjPred: [],
      Tool_SubjPred_Active: false,
      Tool_Syntax_Active: false,
      Tool_Answers_Active: false,
      Tool_Satzhilfe_Active: false,
      Tool_SubjPred_Highlight: false,
      Tool_Steps_No_Gaps: false,
    }
  },
  computed: {
    hasSteps: function () {
      return Object.keys(this.Steps).length > 0;
    },
    hasAnswers: function () {
      return Object.keys(this.$parent.Answers).length > 0;
    },
    hasFarben: function () {
      return Object.keys(this.$parent.Farben).length > 0;
    },
    hasHighlights: function () {
      return Object.keys(this.$parent.Highlights).length > 0;
    },
    hasSubjPred: function () {
      return Object.keys(this.SubjPred).length > 0;
    },
    Answer: function () {
      return this.$parent.Answers.find(Answer => parseInt(Answer.sentence_id) === parseInt(this.$parent.SentenceActiveID));

    },
    active_farben: function () {
      return this.$parent.Farben.filter(Farbe => Farbe.show).map(Farbe => Farbe.id + '-active').join(' ');
    },
    NextSentenceID: function () {
      return parseInt(this.$parent.SentenceActiveID) + 1;
    },
    PreviousSentenceID: function () {
      return parseInt(this.$parent.SentenceActiveID) - 1;
    },
    isNotFirstSentence: function () {
      return this.$parent.SentenceActiveID > 1;
    },
    isNotLastSentence: function () {
      return this.$parent.SentenceActiveID < this.$parent.Sentences.length;
    },
  },
  methods: {
    close () {
      EventBus.$emit('setSentenceAnalysis', false);
      EventBus.$emit('setSentenceActiveID', null);
    },
    openPreviousSentence () {
      if (this.isNotFirstSentence) {
        EventBus.$emit('deactivateAllTools');
        EventBus.$emit('openSentenceByID', this.PreviousSentenceID);
      }
    },
    openNextSentence () {
      if (this.isNotLastSentence) {
        EventBus.$emit('deactivateAllTools');
        EventBus.$emit('openSentenceByID', this.NextSentenceID);
      }
    },
    /**
     * User clicks on word
     * word info is displayed in sublineam container
     * @param e
     * @returns {Promise<void>}
     */
    async setAnalysis (e) {
      this.$refs['sentence_sublineam'].IsLoading_WordAnalysis = true;
      // Does the element have annotations?
      if (e.currentTarget.hasAttribute('corresp')) {
        this.$parent.Reader.getAnnotationAnalysis(e.currentTarget).forEach(AnalysisObject => {
          if (!this.$refs['sentence_sublineam'].AnnotationsContainer.includes(AnalysisObject)) {
            this.$refs['sentence_sublineam'].AnnotationsContainer.push(AnalysisObject);
          }
        });
      }
      let WordAnalysis = await this.$parent.Reader.getWordAnalysis(Reader.getReferenceWord(e.currentTarget), this.$parent.data.config.analysis_api);
      if (!this.$refs['sentence_sublineam'].WordAnalysisContainer.includes(WordAnalysis)) {
        this.$refs['sentence_sublineam'].WordAnalysisContainer.push(WordAnalysis);

      }
      await wait(200);
      this.$refs['sentence_sublineam'].IsLoading_WordAnalysis = false;
    },
    async registerAnalysis () {
      if (!this.Tool_Syntax_Active) {
        for (let Word of this.$refs['sentence_html'].querySelectorAll('tei-w')) {
          Word.addEventListener('click', this.setAnalysis, false);
        }
      }
    },
    async unregisterAnalysis () {
      for (let Word of this.$refs['sentence_html'].querySelectorAll('tei-w')) {
        Word.removeEventListener('click', this.setAnalysis, false);
      }
    },

    async toggleOnModeSubjPred () {
      this.Tool_SubjPred_Active = true;
      await Reader.SubjPred_registerEventListeners(this.$refs['sentence_html'], this.showSubjPredAttributionSentence);
      this.unregisterAnalysis();
    },
    async toggleOffModeSubjPred () {
      this.Tool_SubjPred_Active = false;
      this.Tool_SubjPred_Highlight = false;
      await Reader.SubjPred_removeEventListeners(this.$refs['sentence_html'], this.showSubjPredAttributionSentence);
      this.registerAnalysis();
    },
    async showSubjPredAttributionSentence (e) {
      await Reader.SubjPred_removeHighlightClasses(this.$refs['sentence_html']);
      this.Tool_SubjPred_Highlight = true;
      await Reader.SubjPred_addHighlightClasses(this.$refs['sentence_html'], e);

    },

  },
  mounted: function () {
    // Reading Steps from component-html
    this.Steps = Reader.getSteps(this.$refs.sentence_html);
    // Reading SubjPred from component-html
    this.SubjPred = Reader.getSubjPredElements(this.$refs.sentence_html);
    // EventListeners for Words (tei-w)
    this.registerAnalysis();
    // Event for displaying/hiding gaps between steps
    EventBus.$on('toggleSentenceViewerStepGaps', () => {
      this.Tool_Steps_No_Gaps = !this.Tool_Steps_No_Gaps;
    });
    // Event for activating/deactivating SyntaxMode
    EventBus.$on('toggleSentenceViewerSyntaxMode', () => {
      this.Tool_Syntax_Active = !this.Tool_Syntax_Active;
    });
    // Event for activating/deactivating AnswerMode
    EventBus.$on('toggleSentenceViewerAnswerMode', async (boolean) => {
      this.Tool_Answers_Active = boolean;
    });
    // Über dieses Event wird die Infobar mit einer Nachricht gefüllt
    EventBus.$on('setInfoBarText', string => {
      this.Info = string;
    });

    // Close when ESC is pressed
    document.onkeyup = (e) => {
      e = e || window.event;
      if (e.keyCode == '27') {
        this.close();
      }
    }
  },

}
</script>

<style>

#reader__workspace__analysis--sentence_container {
  min-height: 150px;
}

#reader__workspace__analysis--sentence__annotations:hover .reader__workspace__analysis--sentence__word-analysis--close,
#reader__workspace__analysis--sentence__word-analysis:hover .reader__workspace__analysis--sentence__word-analysis--close {
  visibility: visible;

}

.reader__workspace__analysis--sentence__word-analysis--close {
  width: 1.5rem;
  visibility: hidden;
}


.sentence-viewer__words--left, .sentence-viewer__annotations--left {
  width: 6rem;
}

.hermeneus-analysis .presenter-invisible,
.hermeneus-analysis tei-w.presenter-invisible,
.hermeneus-analysis tei-w.presenter-invisible[corresp],
.hermeneus-analysis w.presenter-invisible,
.hermeneus-analysis w.presenter-invisible[corresp],
.hermeneus-analysis tei-pc.presenter-invisible,
.hermeneus-analysis tei-pc.presenter-invisible[corresp],
.hermeneus-analysis pc.presenter-invisible,
.hermeneus-analysis pc.presenter-invisible[corresp] {
  color: #fff;
  transition: all 300ms ease-in-out;
}


#reader_wrapper .presenter-no-gaps .presenter-invisible,
#reader_wrapper .presenter-no-gaps tei-w.presenter-invisible,
#reader_wrapper .presenter-no-gaps tei-w.presenter-invisible[corresp],
#reader_wrapper .presenter-no-gaps w.presenter-invisible,
#reader_wrapper .presenter-no-gaps w.presenter-invisible[corresp],
#reader_wrapper .presenter-no-gaps tei-pc.presenter-invisible,
#reader_wrapper .presenter-no-gaps tei-pc.presenter-invisible[corresp],
#reader_wrapper .presenter-no-gaps pc.presenter-invisible,
#reader_wrapper .presenter-no-gaps pc.presenter-invisible[corresp] {
  color: #fff;
  display: none;
  transition: width 300ms ease-in-out;
}

.hermeneus-analysis tei-w.presenter-previous,
.hermeneus-analysis tei-w.presenter-previous[corresp],
.hermeneus-analysis tei-pc.presenter-previous,
.hermeneus-analysis tei-pc.presenter-previous[corresp] {
  color: #999999;
  transition: all 300ms linear;
}

.presenter-current {
  color: #000;
  text-shadow: 0px 0px 3px rgba(0, 0, 0, 0.35);
  transition: all 300ms linear;
}


.reader-analysis {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  /*height: 100% !important;*/
}

.reader__analysis__background {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;

}

#reader__workspace__analysis--sentence {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-content: space-between;*/
  background: #fff;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100%;
  line-height: 2rem;
  z-index: 900;
  overflow: scroll;
}


.reader__header--close,
.reader__header--close-button {
  color: #75868a;
}

.reader__header--close-button:hover i {
  color: #326470;
}

.bg-very-light-grey {
  background: #f6f6f6;

}

.reader__sentence-viewer__bottom-grey {
  transition: all 0.3s linear;
  z-index: 999;
  height: auto;
}


/*@media only screen and (min-width: 1366px) {
  #reader__workspace__analysis--sentence {
    box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.18);
    border-top: 5px solid #75868a;
    border-radius: 2px;
    left: 5vw;
    right: 5vw;
    top: 5vh;
    height: auto;
  }
}*/
</style>