<template>
    <div id="reader__workspace__analysis--sentence__toolbar-info"
         class="flex-group-start flex-items-center p7  no-outline text-base light-grey "
         v-html="info"></div>

</template>

<script>
    export default {
        name: "reader-sentence-viewer-infobar",
        props: ['info']
    }
</script>

<style scoped>
    #reader__workspace__analysis--sentence__toolbar-info {
        transition: all 500ms linear;
        min-height: 5.5rem;
    }
</style>