<template>
    <div v-show="this.active" class="text-xl">
        <span class="subj-color mr1">Subjekte </span> und <span class="pred-color ml1"> Prädikate</span>
    </div>
</template>

<script>
    import {EventBus} from "../../../../hermeneus.eventbus/eventbus";

    export default {
        name: "reader-sentence-viewer-tool-subjpred",
        props: {name: {required: true}},
        data: () => {
            return {
                active: false,
                btn_img_src: '/img/svg/hermeneus-btn-attribute_s_p.svg',
                btn_hover_classes: 'hover-subj-pred-color',
                btn_disabled_if: false,
                btn_hidden_if: false,
                btn_infobar_html: `Subjekte und Prädikate im Text anzeigen`,
                btn_html: `Subjekte und Prädikate`

            }
        },
        methods: {
            activate() {
              this.$parent.$parent.toggleOnModeSubjPred();
            },
            deactivate() {
              this.$parent.$parent.toggleOffModeSubjPred();
            }

        }
    }
</script>

<style scoped>

</style>