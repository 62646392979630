<template>
    <div v-show="this.active"  class="color-links">
        <farben v-if="this.active" :farben="this.$parent.$parent.$parent.Farben" class="flex-group-start text-xl" :class="{'w-full bg-white p-4': active, 'bg-very-light-grey': !active}"></farben>
    </div>
</template>

<script>
    import Farben from "../ReaderFarben/Farben.vue";
    import {EventBus} from "../../../../hermeneus.eventbus/eventbus";

    export default {
        name: "reader-sentence-viewer-farben",
        components: {
            'farben': Farben
        },
        props: {links: {required: false}, name: {required: true}},
        data: function () {
            return {
                active: false,
                btn_img_src: '/img/svg/hermeneus-icon-allcolors.svg',
                btn_hover_classes: '',
                btn_disabled_if: false,
                btn_hidden_if: false,
                btn_infobar_html: `Farbmarkierungen im Satz anzeigen`,
                btn_html: `Farbmarkierungen`
            }

        },
        methods: {
            toggle () {
                // Deactivate all color markings when component is deactivated
                if (!this.active) {
                    EventBus.$emit('hideAllFarben')
                }


            },
            activate() {},
            deactivate() {
                    EventBus.$emit('hideAllFarben');


            }
        }
    }
</script>

<style scoped>

</style>