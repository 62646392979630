<template>
    <div v-show="this.active" :class="{'w100': this.active}" class="color-links">
        <highlights v-if="this.active" :highlights="this.$parent.$parent.$parent.Highlights" class="flex-group-start text-xl" context="sentence"></highlights>
    </div>
</template>

<script>
    import {EventBus} from "../../../../hermeneus.eventbus/eventbus";
    import Highlights from "../ReaderHighlights/Highlights.vue";

    export default {
        name: "reader-sentence-viewer-highlights",
        components: {
            'highlights': Highlights
        },
        props: {links: {required: false}, name: {required: true}},
        data: function () {
            return {
                active: false,
                btn_img_src: '/img/svg/hi-markierungen.svg',
                btn_hover_classes: '',
                btn_disabled_if: false,
                btn_hidden_if: false,
                btn_infobar_html: `Farbhinterlegungen im Satz anzeigen`,
                btn_html: `Farbhinterlegungen`
            }

        },
        methods: {
            toggle () {
                // Deactivate all color markings when component is deactivated
                if (!this.active) {
                    EventBus.$emit('hideAllHighlights')
                }


            },
            activate() {},
            deactivate() {
                    EventBus.$emit('hideAllHighlights')


            }
        }
    }
</script>

<style scoped>

</style>