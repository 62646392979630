<template>
  <div v-if="hasSubjPred">
    <button
        @click="toggle()"
        :class="{'olive reader-sentence-viewer_toolbar-button--active':this.active, 'img-grayscale hover-img-color': !this.active}"
        class="p2 reader-toolbar-button button-footer-subj-pred sentence-steps__panel--button flex-items-center light-grey hover-olive mr2 no-outline">
      <hermeneus-icon
          name="hermeneus-btn-attribute_s_p"
          height="32"
          width="32"
          class="hi-color-grey"></hermeneus-icon>
      <span class="inline-block text-xl ml2">Subjekte und Prädikate</span>
    </button>

  </div>
</template>

<script>
import HermeneusIcon from "../../../../hermeneus.icons/HermeneusIcon.vue"
import HasToolSubjPred from "../HasToolSubjPred.vue"

export default {
  name: "reader-toolbar-subjpred",
  extends: HasToolSubjPred,
  components: {
    'hermeneus-icon': HermeneusIcon,
  },

}
</script>

<style scoped>
.toolbar-bottom {

}
</style>