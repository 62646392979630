<template>

  <transition name="popup">
    <div v-show="wordanalysis" class="">
      <div
          ref="wordanalysis_container"
          id="reader__workspace__analysis--vocab"
          :class="{'md-plus:rounded-l-md sm-minus:rounded-md': !$parent.hasVocabAndAnnotationAnalysis }"
          class="reader__workspace__analysis-category sm-minus:rounded-b-md md-plus:rounded-r-md  sm-minus:border-l-4 md-plus:border-t-4 border-green-600 h-full">
        <!--HEADER-->
        <div class="reader__analysis-vocab__header flex flex-row bg-white rounded-tl rounded-tr text-white justify-between items-start text-lg">
          <hermeneus-icon name="hi-glossarium" height="22" width="22" color="hi-color-green-600" class="pt-1 ml-1"></hermeneus-icon>

          <div class="flex flex-row justify-end not-responsive reader__analysis-vocab__header-button-group-grey">

            <button class="reader__analysis-vocab__header-button reader__analysis-vocab__header-button--close p-2 text-base ml-2 align-middle text-green-600 hover:text-green-800"
                    @click="close('WordAnalysis')"><i class="hi hi-close-light"></i>
            </button>
          </div>


        </div>
        <!-- BODY-->
        <div class="reader__analysis-vocab__body  md-minus:p-1 lg-plus:p-3 w-full">
          <loading-dots v-if="$parent.WordAnalysisLoading" class="p-2"></loading-dots>
          <div v-else-if="this.hasResults && isAnalysisFormat('hermeneusv3')" class="reader__analysis-vocab__body__lemma-container p-1">
            <div v-for="(LemmaAnalysis, index, key) in wordanalysis.analysis.Lemmata" class="reader__analysis-vocab__body__lemma-single mb2">
              <div class="reader__analysis-vocab__body__lemma-single__general-info flex flex-row justify-start not-responsive">
                <div class="flex flex-row justify-start ">

                  <div @click="openModalVocabViewer(LemmaAnalysis.pos ? LemmaAnalysis.pos : LemmaAnalysis.wortart, LemmaAnalysis.id)"
                       class=" text-lg  cursor-pointer hover:text-secondary_color flex-shrink " :class="`vocab-analysis__lemma_${index} vocab-analysis__lemma_${LemmaAnalysis.lemma}`">
                    <!--  COMMENT ZWISCHEN ZEILEN VERMEIDET LEERZEICHEN ZWISCHEN INLINE ELEMENTEN-->
                    <span class="inline font-bold text-serif">{{ LemmaAnalysis.lemma }}</span>
                    <!--  WENN DER ERSTE BUCHSTABE DER LEMMA-INFO MIT ( BEGINNT, FÜGE EIN LEERZEICHEN EIN -->
                    <reader-vocab-analysis-lemma-info class="inline" :lemma_info="LemmaAnalysis.info"></reader-vocab-analysis-lemma-info>
                  </div>
                  <div class="reader__analysis-vocab__body__lemma-check" v-if="LemmaAnalysis.lemma">
                    <div class="p0 mr2 align-middle ml-2 text-base text-success" v-if="LemmaAnalysis.is_accepted_reading"><i class="hi hi-circle-checked-filled mt-1"></i></div>
                    <div class="p0 mr2 align-middle " v-else></div>
                  </div>
                  <div
                      v-if="LemmaAnalysis.bedeutung"
                      v-show="!$parent.data.config.hasOwnProperty('analysis_show_bedeutungen') || $parent.data.config.analysis_show_bedeutungen"
                      v-html="LemmaAnalysis.bedeutung"
                      class="reader__analysis-vocab__body__bedeutung text-lg text-grey-700 ml-2 italic flex-shrink">
                  </div>
                </div>
                <button @click="pin(LemmaAnalysis, wordanalysis.line)" :class="`btn-pin_${index}`" class="btn-pin ml-4 text-grey-500 hover:text-secondary-600 text-lg"><i class="hi hi-pin-round"></i>
                </button>
              </div>
              <transition name="slide-fade">
                <morpho-mapper :morpho_json="LemmaAnalysis.morpho" v-if="$parent.WordAnalysisShowMorpho && $parent.data.config.analysis_show_morpho " input_format="wordnet" output_format="de_abbrev" class="mb-4 block"></morpho-mapper>

              </transition>

            </div>

          </div>
          <div v-show="!hasResults && !$parent.WordAnalysisLoading" class="reader__analysis-vocab__body__not-found-container italic text-sm">Kein Eintrag gefunden!</div>
        </div>

      </div>
    </div>
  </transition>
</template>

<script>
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";
import GrammatikKategorien from "../../../../grammatik-kategorien/GrammatikKategorien.vue"
import ReaderVocabAnalysisLemmaInfo from "./ReaderVocabAnalysisLemmaInfo.vue";

export default {
  name: "reader-vocab-analysis",
  props: ['wordanalysis'],
  components: {
    'grammatik-kategorien': GrammatikKategorien,
    'reader-vocab-analysis-lemma-info': ReaderVocabAnalysisLemmaInfo,
  },
  data: function () {
    return {
      isLoading: false,
      ClickedWord: false,
      showMorpho: false,
    }
  },
  computed: {
    hasResults: function () {
      return this.wordanalysis?.analysis?.hasOwnProperty('Lemmata') ? Object.keys(this.wordanalysis.analysis.Lemmata).length > 0 : false;
    }
  },
  methods: {
    openModalVocabViewer (wortart, id) {
      let ModalData = {
        title: 'Vokabel ansehen',
        type: 'success',
        size: 'large',
        component: 'vocab-viewer',
        uri: `/glossarium/view/${wortart}/${id}`,
      };
      EventBus.$emit('OpenModalEvent', JSON.stringify(ModalData));
    },
    /**
     * Clicked word is focused in the workspace
     * :TODO: Extract to parent component!
     * */
    handleWordFocus (WordElement) {
      async function removeFocus (ClickedWord) {
        if (ClickedWord) {
          ClickedWord.classList.remove('reader__word-focus');
        }
      }



      (async () => {
        await removeFocus(this.ClickedWord);
        this.ClickedWord = false;
        this.ClickedWord = WordElement;
        WordElement.classList.add('reader__word-focus');

      })();
    },
    refreshWordAnalysisFromAPI (WordElement) {
      EventBus.$emit('refreshWordAnalysisFromAPI', WordElement);
    },
    /*    hasResults () {
          return this?.wordanalysis?.analysis?.Lemmata?.length > 0;
        },*/

    toggleShowMorpho () {
      this.showMorpho = !this.showMorpho;
    },
    isAnalysisFormat (Format) {
      return this.wordanalysis?.analysis?.hasOwnProperty('Format') && this.wordanalysis?.analysis?.Format === Format;
    },
    close (AnalysisType) {
      this.ClickedWord.classList.remove('reader__word-focus');
      this.ClickedWord = false;
      EventBus.$emit('set' + AnalysisType, false);
    },
    /**
     * @param lemma
     * @param line
     */
    pin (lemma, line) {
      EventBus.$emit('addWordNote', {lemma: lemma, line: line});
    }
  },
  mounted: function () {
    EventBus.$on('wordClicked', async WordElement => {
      await this.handleWordFocus(WordElement);
    });

  }
}
</script>

<style scoped>
.reader__analysis-vocab__body__lemma-container {
  max-height: 90vh;
  overflow-y: scroll;
}

.reader__analysis-vocab__header-button.inactive {
  color: #a3bbbf;
}

.reader__analysis-vocab__morpho {
  transition: all 250ms ease-in-out;
}

.btn-dialog-small:hover {
  color: #326470;
}


.slide-fade-enter-active {
  transition: all .3s ease;
}

.slide-fade-leave-active {
  transition: all .4s ease;
}

.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */
{
  transform: translateY(10px);
  opacity: 0;
}


</style>