<template>

  <div id="reader__titleinfo" class="line-height-md italic">

    <div id="reader__titleinfo__user">
      <div id="reader__titleinfo__user--title" v-html="this.user_title"></div>


      <div id="reader__titleinfo__user--subtitle" v-html="this.user_subtitle"></div>
    </div>
  </div>
</template>

<script>
import QuerySelectors from '../reader.class_queryselectors';

export default {
  name: "reader-title-info",
  props: ['header'],
  data: function () {
    return {
      default_author: this.header.querySelector(QuerySelectors.titlestmt_author).innerHTML,
      default_title: this.header.querySelector(QuerySelectors.titlestmt_title).innerHTML,
      default_locus: this.header.querySelector(QuerySelectors.seriesstmt_biblscope).innerHTML,
      user_author: this.header.querySelector(QuerySelectors.seriesstmt_respstmt_name).innerHTML,
      user_title: this.header.querySelector(QuerySelectors.seriesstmt_title).innerHTML,
      user_subtitle: this.header.querySelector(QuerySelectors.seriesstmt_title_sub).innerHTML,
      showBuchInfo: false,
    };
  },

}
</script>

<style scoped>
#reader__titleinfo {
  color: #888888;
}

#reader__titleinfo__default div {
  display: inline-block;
}


#reader__titleinfo__default--title {
  font-style: italic;
}


</style>