<template>
  <div v-show="farben.length > 0" class="farbe-explanation buttons-footer-farben flex-group-start align-self-center noselect">
    <button @click="toggleAllFarbenOff" class="reader-toolbar-button flex-group-start ml2 flex-items-center mr4 no-outline"
            :class="{'olive reader-sentence-viewer_toolbar-button--active':this.isAnyFarbeActive, 'img-grayscale hover-img-color': !this.isAnyFarbeActive}"
    >

      <hermeneus-icon
          name="hi-farben-min"
          height="32"
          width="32"
          class="hi-color-grey"></hermeneus-icon>
      <span class="inline-block light-grey mr2 text-xl ml2">Farbmarkierungen</span>


    </button>
    <farben :farben="farben" class="flex flex-col md-plus:flex-row justify-start text-lg "></farben>
  </div>
</template>

<script>
import HasToolFarben from "../HasToolFarben.vue"

export default {
  name: "reader-toolbar-farben",
  extends: HasToolFarben,

}
</script>

<style scoped>
.farbe-explanation {

  margin-left: auto;
  margin-right: auto;
}

.farbe-explanation--group {

  vertical-align: middle;
  line-height: 1.2rem;
}

.farbe-explanation--group:hover {

  cursor: pointer;
  background: #fff;
}

.farbe-explanation--group:hover i:before {

  background: #ddd
}


</style>