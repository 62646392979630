<template>
  <div class="flex flex-row justify-start items-center">

    <div class="flex flex-row mr-2">
      <div  v-for="highlight in highlights"
           @mouseover="showDescription(highlight)"
           @mouseout="hideDescription(highlight)"
           class="highlight-explanation--group flex  flex-row  justify-start items-center cursor-pointer p-1 text-base">

      </div>
    </div>
    <div v-html="description" class="text-sm text-primary-300"></div>
  </div>
</template>

<script>
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";

export default {
  name: "highlights-min",
  props: ['highlights'],
  data: function () {
    return {

      description: '',
    }
  },

  methods: {
    showDescription(highlight) {
      this.description = highlight.description;
    },
    hideDescription(highlight) {

      this.description = '';
    },

  },
  mounted () {

  }
}
</script>

<style scoped>
div.highlight--inactive {
  color: #bbb !important;
}
</style>