<template>
    <div id="reader__introduction" class="p-4 line-height-lg bg-primary-300">
        <span class="emphasize bold">Eine kurze Anleitung</span><br>
        Mit dem <span class="italic">hermeneus.reader</span> kannst Du digitale lateinische Texte ganz einfach erschließen:
        Mit einem Klick auf die Satznummer <button class="reader__hermeneus-helper--button-sentence-number mr-2">1</button> am Satzende wird die vergrößerte Satzansicht mit Satzanalyse und weiteren Werkzeugen angezeigt.
        Klicken Sie auf ein einzelnes Wort, um dessen Lemma, morphologische Analyse und Bedeutung anzuzeigen.
        Hervorgehobene Wörter enthalten außerdem Anmerkungen/Kommentare. Mit einem Klick auf
        <i class="hi hi-pin-round text-secondary_color"></i> kannst Du eine Anmerkung
        oder Vokabelinformationen als neben dem Text festpinnen.
        <br>
        <br>
      Auf dem PC kannst du außerdem folgende Tasten benutzen: <br>
      <i class="fas fa-keyboard mr-1"></i> <span class="font-bold">ESC</span>:  Satzansicht-Fenster schließen <br>
      <i class="fas fa-keyboard mr-1"></i> <span class="font-bold">1-9</span>:  Entsprechenden Satz in der Satzansicht aufrufen
      <br><br>
        <div class="reader__introduction__warning">

            <button class="bg-secondary white hover-bg-secondary-dark p3 mt4 rounded shadow" @click="hideIntroduction">OK & Schließen</button>
        </div>
    </div>
</template>

<script>
    import {EventBus} from "../../../hermeneus.eventbus/eventbus";

    export default {
        name: "reader-introduction",
        data: function () {
            return {
                show: true,
            }
        },
        methods: {
            hideIntroduction () {
                EventBus.$emit('closeReaderIntroduction');
            }
        },
    }
</script>

<style scoped>

</style>