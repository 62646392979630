<template>
  <span :class="{'lemma-info-latin serif': isStartingWithComma, 'lemma-info-german italic ml-1': !isStartingWithComma}" class="mr-2">{{ isStartingWithBracket ? ' ' : '' }}{{ lemma_info }}:</span>
</template>

<script>
export default {
  name: "reader-vocab-analysis-lemma-info",
  props: ['lemma_info'],
  computed: {
    isStartingWithComma: function () {
      return this.lemma_info[0] === ','
    },
    isStartingWithBracket: function () {
      return this.lemma_info[0] === '('
    }
  }
}
</script>

<style scoped>
.lemma-info-latin {}
.lemma-info-german {}
</style>