<template>
    <div id="reader__workspace__primary">
        <div
                id="reader__workspace__primary--text"
                class="hermeneus-reader-text noselect"
                :class="[active_farben, `stylesheet-${this.$parent.data.config.stylesheet} ${this.$parent.data.textdata.format ? 'format-' + this.$parent.data.textdata.format : ''}`, {
                            'show-subj-pred': $parent.Tool_SubjPred_Show,
                            'subj-pred-highlight': $parent.Tool_SubjPred_Highlight,
                            'double-spacing': $parent.PrintDoubleSpacing,
                            // Übergeordnete CSS-Klasse, die nur vorhanden ist, wenn die Markierungen im Text gezeigt werden sollen
                            'show-highlight1-text': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight1') && (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight1').showText,
                            'show-highlight2a-text': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight2a') && (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight2a').showText,
                            'show-highlight2b-text': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight2b') && (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight2b').showText,
                            'show-highlight2c-text': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight2c') && (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight2c').showText,
                            'show-highlight2d-text': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight2d') && (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight2d').showText,
                            'show-highlight3-text': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight3') && (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight3').showText,
                            'show-highlight4-text': this.$parent.Highlights.find(HighlightElement => (HighlightElement.id ==='highlight4') && (HighlightElement.show === true))  && this.$parent.Highlights.find(HighlightElement => HighlightElement.id ==='highlight4').showText,
                            }]"
                v-html="this.body"></div>
    </div>
</template>

<script>
    export default {
        name: "reader-workspace",
        props: ['body'],
        computed: {
            active_farben: function () {
                return this.$parent.Farben.filter(Farbe => Farbe.show).map(Farbe => Farbe.id + '-active').join(' ');
            }
        }
    }
</script>

<style scoped>

</style>