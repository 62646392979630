<template>
  <div id="reader__toolbar" class="md-plus:p-8 w-full">
    <reader-toolbar-farben :farben="$parent.$parent.Farben" class="reader__toolbar-toolcomponent"></reader-toolbar-farben>
    <reader-toolbar-subjpred class="reader__toolbar-toolcomponent"></reader-toolbar-subjpred>
    <reader-toolbar-highlight :highlights="$parent.$parent.Highlights" class="reader__toolbar-toolcomponent"></reader-toolbar-highlight>
  </div>
</template>

<script>


import ReaderToolbarFarben from "./ReaderFooterToolbarFarben.vue"
import ReaderToolbarSubjPred from "./ReaderFooterToolbarSubjPred.vue"
import ReaderFooterToolbarHighlight from "./ReaderFooterToolbarHighlights.vue"

export default {
  name: "reader-footer-toolbar",

  components: {
    'reader-toolbar-highlight': ReaderFooterToolbarHighlight,
    'reader-toolbar-farben': ReaderToolbarFarben,
    'reader-toolbar-subjpred': ReaderToolbarSubjPred,
  }
}
</script>

<style scoped>
.reader__toolbar-toolcomponent {
  padding: 0.5rem;
}
</style>