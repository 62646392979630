<template>
  <div v-if="this.active" class="sentence-steps no-outline noselect flex-group-start flex-wrap" :class="{'w100': this.active}">
    <div class="reader-sentence-viewer__tool-content flex-group-start w100">
      <div class="sentence-steps__container flex-group-start text-grey-500 p-1">
        <button
            @click="switchStep(0)" :class="{'text-satzschritte': isCurrentStep(0)}"
            class="p-2 hover:text-satzschritte_light no-outline mr-3">
          <i class="far fa-stop-circle text-lg"></i>
        </button>
        <button
            v-for="(step, key) in this.steps"
            @click="switchStep(key)" :class="{'text-satzschritte font-bold': isCurrentStep(key)}"
            class="p-1 flex flex-row justify-start items-center no-outline text-xl hover:text-satzschritte_light">
          <i v-show="key !== 0" class="fas fa-caret-right text-xs mr-2"></i>
          <div>{{ key }}</div>
        </button>
      </div>
      <button v-if="this.active && this.CurrentStep > 0"
              @click="this.toggleStepGaps"
              @mouseover="$parent.setInfobarText('Lücken zwischen den Wörtern verstecken/anzeigen')"
              @mouseout="$parent.setInfobarText('')"
              :class="{'text-satzschritte': this.$parent.$parent.Tool_Steps_No_Gaps}"
              class="p2 no-outline text-lg hover:text-satzschritte_light ml-2 mr3">
        <i class="fas fa-compress-arrows-alt"></i>
      </button>
    </div>
    <div class="w100 mt3">
      <div class="text-lg line-height-md light-grey"><span class="italic">Klicken Sie auf die Zahl, um den entsprechenden Satzschritt anzuzeigen
                    oder verwenden Sie die Pfeiltasten</span> ( <i class="far fa-caret-square-left mr-1"></i> <i
          class="far fa-caret-square-right"></i> ).
      </div>
    </div>
  </div>
</template>

<script>

import {EventBus} from "../../../../hermeneus.eventbus/eventbus.js";

export default {
  name: "reader-sentence-viewer-tool-steps",
  props: {steps: {required: true}, name: {required: true}},
  data: () => {
    return {
      CurrentStep: 0,
      active: false,
      btn_infobar_html: `Satzschritt-Werkzeug`,
      btn_img_src: '/img/svg/hermeneus-btn-steps.svg',
      btn_disabled_if: false,
      btn_hidden_if: false,
      btn_hover_classes: '',
      btn_html: `Satzschritte`
    }
  },

  methods: {
    activate () {
      this.switchStep(0);
      // For DemoTour
      EventBus.$emit('SentenceViewerStepsActivated');
      this.setAllInvisible();
    },
    deactivate () {
      this.setAllvisible();
    },
    /**
     * Compress word gaps
     */
    toggleStepGaps () {
      EventBus.$emit('toggleSentenceViewerStepGaps');
    },
    isCurrentStep (key) {
      return parseInt(key) === this.CurrentStep;
    },
    switchStep (key) {
      for (let Step in this.steps) {
        this.CurrentStep = parseInt(key);
        let SwitchedStep = parseInt(key);
        let LoopedStep = parseInt(Step);
        let Elements = this.steps[Step];
        // Apply grey for all previous Steps
        if (LoopedStep < SwitchedStep) {

          this.removeClass(Elements, 'presenter-invisible');
          this.removeClass(Elements, 'presenter-current');
          this.addClass(Elements, 'presenter-previous')
        }
        // Apply black for current Step
        else if (LoopedStep === SwitchedStep) {
          this.removeClass(Elements, 'presenter-invisible');
          this.removeClass(Elements, 'presenter-previous');
          this.addClass(Elements, 'presenter-current')
        }
        // Cover all other Steps
        else {
          this.addClass(Elements, 'presenter-invisible');
          this.removeClass(Elements, 'presenter-previous');
          this.removeClass(Elements, 'presenter-current')
        }
      }
    },
    addClass (ElementArray, Classname) {
      ElementArray.forEach(Element => Element.classList.add(Classname));
    },
    removeClass (ElementArray, Classname) {
      ElementArray.forEach(Element => Element.classList.remove(Classname));
    },
    setAllInvisible () {
      for (let Step in this.steps) {
        this.addClass(this.steps[Step], 'presenter-invisible');
      }
    },
    setAllvisible () {
      for (let Step in this.steps) {
        this.steps[Step].forEach(Element => {
          Element.classList.remove('presenter-invisible', 'presenter-current', 'presenter-visible');
        });

      }
    },

  },
  mounted () {

    document.onkeydown = (e) => {
      e = e || window.event;
      if (e.keyCode == '37') {
        this.CurrentStep > 0 ? this.switchStep(this.CurrentStep - 1) : '';
      } else if (e.keyCode == '39') {
        this.CurrentStep <= Object.keys(this.steps).length ? this.switchStep(this.CurrentStep + 1) : '';
      } else if (e.keyCode == '32') {
        this.toggle();
      } else {
      }
    }
  }
}
</script>

<style scoped>
</style>