<template>
  <div>
    <div @mouseover="show(highlight)" @mouseout="hide(highlight)" @click="toggleShowHighlight(highlight)" v-for="highlight in highlights"
         v-show="(highlight.showSentence && context === 'sentence') || (highlight.showText && context === 'text')"
         :class="{'justify-start': context === 'text', 'justify-center': context === 'sentence'}"
         class="flex flex-row  items-center cursor-pointer ml-5 noselect  text-base  md-plus:text-lg rounded-sm text-grey-700 hover:text-secondary-500">
      <i v-if="highlight.show" class="hi hi-checkbox-checked inline-block align-self-center"></i>
      <i v-else class="hi hi-checkbox-unchecked inline-block align-self-center"></i>
      <div v-html="highlight.description" class="ml2" :class="`${highlight.id}_legende`"></div>
    </div>
  </div>
</template>

<script>
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";

export default {
  name: "highlights",
  props: {
    highlights: {
      type: Array,
      required: true
    },
    context: {
      type: String,
      required: false,
      default: 'text'
    },
  },
  methods: {
    show (highlight) {
      EventBus.$emit('highlightHighlight', highlight)
    }, hide (highlight) {
      EventBus.$emit('dehighlightHighlight', highlight)
    },
    toggleShowHighlight (highlight) {
      EventBus.$emit('toggleShowHighlight', highlight)
    }
  },
}
</script>

<style scoped>
</style>