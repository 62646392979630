<template>
  <div v-if="morpho" class="bestimmungen-container">
            <span v-for="bestimmung in formattedbestimmungen" class="analysis__grammatikkategorien--container">
                <span
                    v-for="(attribute, index) in bestimmung"
                    class="analysis__grammatikkategorien__kategorie"
                    v-bind:class="classObject(index, bestimmung.length)">
                  <span v-if="attribute">
                    <span v-text="attribute.short" class="analysis__grammatikkategorien__kategorie--short"></span>
                    <span v-text="attribute.long" class="analysis__grammatikkategorien__kategorie--long"></span>
                  </span>
                </span>
            </span>
  </div>
</template>

<script>
import Helpers from "../helpers/class_helpers";

export default {
  name: "grammatik-kategorien",
  props: ['morpho'],
  data: function () {
    return {
      Blacklisted: ['normal', 'alternativ', 'adjektiv', 'adverb', 'nomen', 'partikel', 'pronomen', 'verb'],
      AttributeFormatter: {
        // WORTARTEN
        '4_adv': {'short': 'Adv.', 'long': 'Adverb'},
        // MODI
        'infinitiv': {'short': 'Inf.', 'long': 'Infinitiv'},
        'indikativ': {'short': 'Ind.', 'long': 'Indikativ'},
        'konjunktiv': {'short': 'Konj.', 'long': 'Konjunktiv'},
        'partizip': {'short': 'Part.', 'long': 'Partizip'},
        'imp': {'short': 'Imp.', 'long': 'Imperativ'},
        '0_infinitiv': {'short': 'Inf.', 'long': 'Infinitiv'},
        '1_indikativ': {'short': 'Ind.', 'long': 'Indikativ'},
        '2_konjunktiv': {'short': 'Konj.', 'long': 'Konjunktiv'},
        '3_imperativ': {'short': 'Imp.', 'long': 'Imperativ'},
        '4_partizip': {'short': 'Part.', 'long': 'Partizip'},
        // STEIGERUNG
        '1_pos': {'short': 'Pos.', 'long': 'Positiv'},
        '2_komp': {'short': 'Komp.', 'long': 'Komparativ'},
        '3_superl': {'short': 'Superl.', 'long': 'Superlativ'},
        //CASES
        '1_nom': {'short': 'Nom.', 'long': 'Nominativ'},
        '2_gen': {'short': 'Gen.', 'long': 'Genitiv'},
        '3_dat': {'short': 'Dat.', 'long': 'Dativ'},
        '4_akk': {'short': 'Akk.', 'long': 'Akkusativ'},
        '5_vok': {'short': 'Vok.', 'long': 'Vokativ'},
        '6_abl': {'short': 'Abl.', 'long': 'Ablativ'},
        'nom': {'short': 'Nom.', 'long': 'Nominativ'},
        'gen': {'short': 'Gen.', 'long': 'Genitiv'},
        'dat': {'short': 'Dat.', 'long': 'Dativ'},
        'akk': {'short': 'Akk.', 'long': 'Akkusativ'},
        'vok': {'short': 'Vok.', 'long': 'Vokativ'},
        'abl': {'short': 'Abl.', 'long': 'Ablativ'},
        // GENERA
        '1_mask': {'short': 'm.', 'long': 'maskulin'},
        '2_fem': {'short': 'f.', 'long': 'feminin'},
        '3_neutr': {'short': 'n.', 'long': 'neutral'},
        'mask': {'short': 'Mask.', 'long': 'Maskulin'},
        'fem': {'short': 'Fem.', 'long': 'Feminin'},
        'neutr': {'short': 'Neutr.', 'long': 'Neutrum'},
        'm': {'short': 'm.', 'long': 'maskulin'},
        'f': {'short': 'f.', 'long': 'feminin'},
        'n': {'short': 'n.', 'long': 'neutral'},
        // NUMERI
        'sg': {'short': 'Sg.', 'long': 'Singular'},
        'pl': {'short': 'Pl.', 'long': 'Plural'},
        '1_sg': {'short': 'Sg.', 'long': 'Singular'},
        '2_pl': {'short': 'Pl.', 'long': 'Plural'},
        'sg1': {'short': '1.Ps.Sg.', 'long': '1. Person Singular'},
        'sg2': {'short': '2.Ps.Sg.', 'long': '2. Person Singular'},
        'sg3': {'short': '3.Ps.Sg.', 'long': '3. Person Singular'},
        'pl1': {'short': '1.Ps.Pl.', 'long': '1. Person Plural'},
        'pl2': {'short': '2.Ps.Pl.', 'long': '2. Person Plural'},
        'pl3': {'short': '3.Ps.Pl.', 'long': '3. Person Plural'},
        '1_sg1': {'short': '1.Ps.Sg.', 'long': '1. Person Singular'},
        '2_sg2': {'short': '2.Ps.Sg.', 'long': '2. Person Singular'},
        '3_sg3': {'short': '3.Ps.Sg.', 'long': '3. Person Singular'},
        '4_pl1': {'short': '1.Ps.Pl.', 'long': '1. Person Plural'},
        '5_pl2': {'short': '2.Ps.Pl.', 'long': '2. Person Plural'},
        '6_pl3': {'short': '3.Ps.Pl.', 'long': '3. Person Plural'},
        // TEMPORA
        'praesens': {'short': 'Präs.', 'long': 'Präsens'},
        'futur': {'short': 'Fut.', 'long': 'Futur'},
        'imperfekt': {'short': 'Impf.', 'long': 'Imperfekt'},
        'perfekt': {'short': 'Pf.', 'long': 'Perfekt'},
        'plqpf': {'short': 'Plqpf.', 'long': 'Plusquamperfekt'},
        '1_praesens': {'short': 'Präs.', 'long': 'Präsens'},
        '2_imperfekt': {'short': 'Impf.', 'long': 'Imperfekt'},
        '3_perfekt': {'short': 'Pf.', 'long': 'Perfekt'},
        '4_plqpf': {'short': 'Plqpf.', 'long': 'Plusquamperfekt'},
        '4_plusquamperfekt': {'short': 'Plqpf.', 'long': 'Plusquamperfekt'},
        '5_futur': {'short': 'Fut.', 'long': 'Futur'},
        // GENERA VERBI
        'aktiv': {'short': 'Akt.', 'long': 'Aktiv'},
        'passiv': {'short': 'Pass.', 'long': 'Passiv'},
        'gerundium': {'short': 'Gerund', 'long': 'Gerund'},
        'gerundiv': {'short': 'Gerundiv', 'long': 'Gerundiv'},
        'supin': {'short': 'Sup.', 'long': 'Supin'},
        'supin1': {'short': 'Sup1.', 'long': 'Supin 1'},
        'supin2': {'short': 'Sup2.', 'long': 'Supin 2'},
        '1_aktiv': {'short': 'Akt.', 'long': 'Aktiv'},
        '2_passiv': {'short': 'Pass.', 'long': 'Passiv'},
        '3_gerundium': {'short': 'Gerund', 'long': 'Gerund'},
        '4_gerundiv': {'short': 'Gerundiv', 'long': 'Gerundiv'},
        '5_supin': {'short': 'Sup.', 'long': 'Supin'},
        '1_supin1': {'short': 'Sup1.', 'long': 'Supin 1'},
        '2_supin2': {'short': 'Sup2.', 'long': 'Supin 2'},
        // NUMERALIA
        '4_multiplikativ': {'short': 'multipl.', 'long': 'multiplikativ'},
        '3_distributiv': {'short': 'distribut.', 'long': 'distributiv'},
        '2_distributiv': {'short': 'ord.', 'long': 'ordinal'},
        '1_kardinal': {'short': 'kard.', 'long': 'kardinal'},
      },
    }
  },
  computed: {
    formattedbestimmungen: function () {
      return this.morpho.map(Bestimmungen => {
        let BestimmungenArray = Array.isArray(Bestimmungen) ? Bestimmungen : [];
        return BestimmungenArray.map(Attribute => {
          if (Helpers.notInArray(Attribute, this.Blacklisted)) {
            if (this.AttributeFormatter.hasOwnProperty(Attribute)) {
              return this.AttributeFormatter[Attribute];
            }
            return {'short': Attribute, 'long': ''};
          }
          return null;
        }).filter(item => {
          return item !== null
        });
      });
    },

  },
  methods: {
    classObject (index, length) {
      return {
        'first': index === 0,
        'last': index === length - 1
      }
    },
    format (Attribute) {
      if (this.AttributeFormatter.hasOwnProperty(Attribute)) {
        return this.AttributeFormatter[Attribute];
      }
      return Attribute;
    },
    reformat (Attribute) {
      if (this.AttributeFormatter.hasOwnProperty(Attribute)) {
        return this.AttributeFormatter[Attribute];
      }
      return Attribute;
    },
    formatted (BestimmungenArray) {
      //return attribute;
    }

  },
}
</script>

<style scoped>
.analysis__grammatikkategorien--container {
  display: block;
  width: auto;
  text-transform: capitalize;
  line-height: normal;
  margin-bottom: 0.25rem;
}


.analysis__grammatikkategorien__kategorie {
  display: inline-block;
  color: #aaa;
  margin-right: 1px;
  font-size: 10px;
}

.analysis__grammatikkategorien__kategorie .analysis__grammatikkategorien__kategorie--long {
  display: none;
}

@media only screen and (min-width: 960px) {
  .bestimmungen-container {
    margin-top: 0.5rem;
  }

  .analysis__grammatikkategorien--container:hover .analysis__grammatikkategorien__kategorie {
    background: #326470;
  }

  .analysis__grammatikkategorien__kategorie {
    display: inline-block;
    color: #fff;
    margin: 1px 0 0 1px;
    background: #75868a;
    padding: 5px;
  }


  .analysis__grammatikkategorien__kategorie:hover .analysis__grammatikkategorien__kategorie--long {
    display: inline-block;
  }

  .analysis__grammatikkategorien__kategorie:hover .analysis__grammatikkategorien__kategorie--short {
    display: none;
  }
}


.analysis__grammatikkategorien__kategorie.first {
  border-radius: 3px 0px 0px 3px;
}

.analysis__grammatikkategorien__kategorie.last {
  border-radius: 0px 3px 3px 0px;
}
</style>