<template>
  <div class="p6 no-outline noselect">
    <div v-show="this.WordAnalysisContainer.length > 0"
         class="sentence-viewer__words flex-group-start">
      <div class="sentence-viewer__words--left p-2">
        <hermeneus-icon name="hi-glossarium" height="24" width="24" color="hi-color-green-600"></hermeneus-icon>

      </div>
      <div class="sentence-viewer__words--right w100 inline ml2">
        <transition-group name="popup">
          <div @click="removeFromWordAnalysisContainer(index)"
               v-for="(WordAnalysis, index, key) in this.WordAnalysisContainer"
               :key="index"
               id="reader__workspace__analysis--sentence__word-analysis"
               class="text-xl cursor-pointer hover-red hover-crossed mt1 mr2">
            <div class="reader__workspace__analysis--sentence__word-analysis--lemma-single flex flex-row justify-start flex-wrap flex-shrink normal-grey w100 mr3"
                 v-for="(bestimmung, index, key) in WordAnalysis.analysis.Lemmata">
              <div class="reader__workspace__analysis--sentence__word-analysis--lemma-single__general-info flex flex-row flex-shrink flex-wrap justify-start">
                <div v-html="bestimmung.lemma" class="text-serif bold"></div>
                <div v-html="bestimmung.info" class="text-serif"></div>
                <div v-if="bestimmung.bedeutung"
                     v-html="bestimmung.bedeutung"
                     class="reader__workspace__analysis--sentence__word-analysis__body__bedeutung ml-5 italic"></div>
              </div>
            </div>
          </div>
        </transition-group>
      </div>
    </div>

    <div v-show="this.AnnotationsContainer.length > 0"
         class="sentence-viewer__annotations flex-group-start mt6">
      <div class="sentence-viewer__annotations--left p-2">
        <hermeneus-icon name="hi-annotation-min" height="24" width="24" color="hi-color-annotation-500"></hermeneus-icon>
      </div>
      <div class="sentence-viewer__annotations--right ml2 w100 ">
                <span
                    id="reader__workspace__analysis--sentence__annotations"
                    @click="removeFromAnnotationContainer(index)" v-for="(Annotation, index, key) in this.AnnotationsContainer"
                    class="mt1 cursor-pointer hover-red hover-crossed mr3">
                        <span class="reader__workspace__analysis--sentence__word-analysis--lemma-single normal-grey w100">
                            <span class="reader__workspace__analysis--sentence__word-analysis--lemma-single__general-info mb1">
                                <span v-html="Annotation.referenced_text" class="text-serif bold mr2 text-xl"></span>
<hermeneus-markdown-wrapper :markdown="Annotation.annotation_text" class="reader__workspace__analysis--sentence__word-analysis__body__bedeutung text-xl italic"></hermeneus-markdown-wrapper>
                            </span>
                        </span>
                </span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "reader-sentence-viewer-sub-lineam",
  components: {
  },
  data: () => {
    return {
      WordAnalysisContainer: [],
      AnnotationsContainer: [],
      IsLoading_WordAnalysis: false,
    }
  },
  methods: {

    async removeFromWordAnalysisContainer (RemoveIndex) {
      this.WordAnalysisContainer = this.WordAnalysisContainer.filter((analyis, index) => {
        return index !== RemoveIndex;
      });
    },
    async removeFromAnnotationContainer (RemoveIndex) {
      this.AnnotationsContainer = this.AnnotationsContainer.filter((annotation, index) => {
        return index !== RemoveIndex;
      });
    },

  }
}
</script>

<style scoped>

</style>