<template>

</template>

<script>
    import Helpers from "../../../helpers/class_helpers";
    import {EventBus} from "../../../hermeneus.eventbus/eventbus";
    export default {
        name: "has-notes",
        props: ['lines', 'wordnotes', 'annotationnotes'],
        components: {
        },
        methods: {
            hasFollowingNote (KeyOfElement) {
            },
            removeWordNote (WordNote) {
                EventBus.$emit('removeWordNote', WordNote);
            },
            removeAnnotationNote (AnnotationNote) {
                EventBus.$emit('removeAnnotationNote', AnnotationNote);
            },

            /**
             * Expand annotation so its full content is visible
             * @param Event
             */
            togglefullheight: function (Event) {
                let fullviewbtn = Event.currentTarget;
                let lineContainer = fullviewbtn.parentElement;
                lineContainer.querySelector('#reader__ad-lineam .reader__notes__btn-view-normal').style.display = "inline-block";
                fullviewbtn.style.display = "none";
                lineContainer.classList.remove('note-height__minimized');
                lineContainer.classList.add('note-height__full');
            },
            /**
             * Reduce height of annotation to its given height('.note-height__minimized')
             * @param Event
             */
            togglenormalheight: function (Event) {
                let normalviewbtn = Event.currentTarget;
                let lineContainer = normalviewbtn.parentElement;
                lineContainer.classList.add('note-height__minimized');
                lineContainer.classList.remove('note-height__full');
                lineContainer.querySelector('#reader__ad-lineam .reader__notes__btn-view-full').style.display = "inline-block";
                normalviewbtn.style.display = "none";

            },
            fadeinremovebuttons: function (Event) {
                let lineContainer = Event.currentTarget;
                let removeButtons = lineContainer.querySelectorAll('.btn-dialog-small');
                removeButtons.forEach(RemoveButton => {
                    RemoveButton.classList.remove('fadeOut');
                    RemoveButton.classList.add('fadeIn');
                })
            },
            fadeoutremovebuttons: function (Event) {
                let lineContainer = Event.currentTarget;
                let removeButtons = lineContainer.querySelectorAll('.btn-dialog-small');
                removeButtons.forEach(RemoveButton => {
                    RemoveButton.classList.remove('fadeIn');
                    RemoveButton.classList.add('fadeOut');
                })
            },
            toggleviewbuttons () {
                document.querySelectorAll('#reader__ad-lineam .reader__notes__line-container').forEach(RefElement => {
                    if (Helpers.isOverflowing(RefElement)) {
                        RefElement.querySelector('#reader__ad-lineam .reader__notes__btn-view-full').style.display = "inline-block";
                    } else {
                        RefElement.querySelector('#reader__ad-lineam .reader__notes__btn-view-full').style.display = "none";
                    }

                });
            }


        },
        updated: function () {
            this.toggleviewbuttons();
        },
    }
</script>

<style scoped>

</style>