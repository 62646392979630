<template>

</template>

<script>
import HermeneusIcon from "../../../../hermeneus.icons/HermeneusIcon.vue"
import Highlights from "./Highlights.vue"
import HighlightsMin from "./HighlightsMin.vue"

export default {
  name: "HasToolHighlights",
  components: {
    'highlights': Highlights,
    'highlights-min': HighlightsMin,
    'hermeneus-icon': HermeneusIcon,
  },

  props: ['highlights'],

}
</script>

<style scoped>

</style>