<template>
  <div id="reader__ad-lineam"
       :class="[{'double-spacing': $parent.PrintDoubleSpacing}]"
  >
    <div v-for="line in lines">
      <div
          class="reader__notes__line-container flex flex-row flex-shrink flex-wrap"
          :class="line.type ? 'type_' +  line.type : ''"
          :id="'line_' + line.line_no"
          ref="linecontainers"
      >
        <!--WORDNOTES-->
        <div
            v-for="(wordnote, key, index) in filteredWordNotes(line)"
            @click="removeWordNote(wordnote)"
            :id="'wordnote_' + key"
            class="reader__notes__note-container flex flex-row justify-start flex-wrap hover-crossed hover-secondary cursor-pointer">
          <div class="reader__notes__note-lemma text-serif bold black">{{ wordnote.lemma.lemma }}</div>
          <reader-vocab-analysis-lemma-info class="reader__notes__note-info black " :lemma_info="wordnote.lemma.info"></reader-vocab-analysis-lemma-info>
          <div class="">{{ wordnote.lemma.bedeutung }}</div>
          <div class="reader__notes__note-divider"></div>
        </div>


        <!--ANNOTATIONNOTES-->
        <span
            class="reader__notes__note-container mr-3 hover-crossed hover-secondary cursor-pointer"
            :class="'type-' + annotationnote.type"
            @click="removeAnnotationNote(annotationnote)"
            v-for="(annotationnote, key, index) in filteredAnnotationNotes(line)"
            :id="'annotationnote_' + key"
           >
                                <span v-if="annotationnote.render_referenced_elements != 'false' && annotationnote.referenced_text.length > 0"
                                      class="text-serif bold reader__notes__annotation-reference mr-2">{{ annotationnote.referenced_text.trim() }}: </span>
                                    <hermeneus-markdown-wrapper :markdown="annotationnote.annotation_text" class="inline-block"></hermeneus-markdown-wrapper>

                            </span>

        <button @click="togglefullheight" class="reader__notes__btn-view-full hover:bg-grey-200 rounded-md"><i class="fas fa-caret-left text-lg"></i></button>
        <button @click="togglenormalheight" class="reader__notes__btn-view-normal hover:bg-grey-200 rounded-md"><i class="fas fa-caret-down text-lg"></i></button>
      </div>
    </div>
  </div>

</template>

<script>
import HasNotes from "./HasNotes.vue";
import ReaderVocabAnalysisLemmaInfo from "./ReaderVocabAnalysis/ReaderVocabAnalysisLemmaInfo.vue";

export default {
  extends: HasNotes,
  name: "reader-ad-lineam",
  components: {
    'reader-vocab-analysis-lemma-info': ReaderVocabAnalysisLemmaInfo
  },

  setup (props) {
    const filteredWordNotes = (line) => {
      return props.wordnotes.filter(wordnote => wordnote.line === line.line_no);
    }
    const filteredAnnotationNotes = (line) => {
      return props.annotationnotes.filter(annotationnote => annotationnote.line === line.line_no);
    }
    return {filteredWordNotes, filteredAnnotationNotes}
  }


}
</script>

<style scoped>
.reader__notes__line-container {
  position: relative;
  width: 100%;
  flex-wrap: wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reader__notes__line-container .btn-dialog-small {
  width: 0;
  color: #fff;
  display: inline;
  font-size: 0.85rem;
  transition: width 0.3s ease-in-out;
}

.reader__notes__line-container:hover .btn-dialog-small {
  display: inline;
  width: 0.85rem;
  color: #ddd;
}

.reader__notes__line-container:hover .btn-dialog-small:hover {
  color: #326470;
}


.reader__notes__annotation-reference, .reader__notes__note-lemma {
  display: inline;
}

.reader__notes__note-text {
  display: inline;
  line-break: loose;
  word-wrap: break-word;
  white-space: nowrap;
  word-break: break-all;
  overflow: hidden;
}

.reader__notes__note-divider {
  margin: 0 0.5rem;
}

.reader__highlight {
  text-shadow: 0px 0px 3px rgba(150, 150, 150, 0.5);
}

.reader__notes__btn-view-full, .reader__notes__btn-view-normal {
  display: none;
  margin: 0;
  padding: 0;
  height: 2rem;
  width: 2rem;
  position: absolute;
  color: #75868a;
  right: 0;
  top: 0;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 20%, rgba(255, 255, 255, 0.85) 100%);
  background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 20%, rgba(255, 255, 255, 0.85) 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 20%, rgba(255, 255, 255, 0.85) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
}

.reader__notes__btn-view-full:hover, .reader__notes__btn-view-normal:hover {
  color: #326470;
  border: 2px solid #326470;
}

.note-height__full {
  height: auto !important;
}

@media only screen and (min-width: 960px) {
  .reader__notes__line-container {
    font-size: 1rem;
  }
}

@media only screen and (min-width: 960px) {
  .reader__notes__line-container {
    font-size: 0.85rem;
  }
}
</style>