<template>
  <div v-show="(highlights.length > 0) && (highlights.filter(highlight => highlight.showText === true)).length > 0" class=" flex flex-row justify-start items-start noselect">
    <button class="reader-toolbar-button flex-group-start ml2 flex-items-center mr4 no-outline"
            :class="{'olive reader-sentence-viewer_toolbar-button--active':true, 'img-grayscale hover-img-color': false}">

      <hermeneus-icon
          name="hi-markierungen"
          height="45"
          width="45"
          class="hi-color-grey"></hermeneus-icon>
      <span class="inline-block light-grey mr2 text-xl ml2">Hervorhebungen:</span>


    </button>
    <highlights :highlights="highlights" class="flex flex-col justify-start text-lg mt-3"></highlights>
  </div>
</template>

<script>
import HasToolHighlights from "../ReaderHighlights/HasToolHighlights.vue"

export default {
  name: "reader-toolbar-highlights",
  extends: HasToolHighlights,

}
</script>

<style scoped>


</style>