<template>
  <div v-show="this.active" :class="{'w100': this.active}">
    <div>

    Automatische Syntaxanalyse des Satzes. Fahren Sie über markierte Wörter, um die Satzfunktion anzuzeigen.
    </div>
  </div>
</template>

<script>
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";

export default {
  name: "reader-sentence-viewer-tool-syntax",
  components: {
  },
  props: { name: {required: true}},
  data: function () {
    return {
      active: this.$parent.$parent.Tool_Syntax_Active,
      btn_img_src: '/img/svg/hi-syntax.svg',
      btn_hover_classes: '',
      btn_disabled_if: false,
      btn_hidden_if: false,
      btn_infobar_html: `Syntaxanalyse`,
      btn_html: `Syntax`
    }

  },
  methods: {
    toggle () {
      // Deactivate all color markings when component is deactivated
        EventBus.$emit('toggleSentenceViewerSyntaxMode')


    }
  }
}
</script>

<style scoped>

</style>