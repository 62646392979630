<template>

  <transition name="popup">
    <div v-if="annotationanalysis.length>0" class="no-outline">
      <!--ANNOTATION-ANALYSIS-->
      <div id="reader__workspace__analysis--annotation"
           :class="{'md-plus:rounded-r-md sm-minus:rounded-md': !$parent.hasVocabAndAnnotationAnalysis }"

           class="reader__workspace__analysis-category sm-minus:rounded-t-md md-plus:rounded-l-md  sm-minus:border-l-4 md-plus:border-t-4 bg-white  border-annotation-500 h-full ">
        <div class="reader__workspace__analysis-header flex flex-row items-start justify-between rounded-t p-1">
          <hermeneus-icon name="hi-annotation-min" height="26" width="26" color="hi-color-annotation-500"></hermeneus-icon>
          <button @click="close('AnnotationAnalysis')" class="text-annotation-500 hover:text-annotation-700 p-1">
            <i class="hi hi-close-light align-middle"></i>
          </button>
        </div>
        <div class="reader__workspace__analysis-body p-2">
          <div v-for="annotation in this.annotationanalysis"
               @mouseover="highlight(annotation)"
               @mouseout="dehighlight(annotation)"
               class="w-full">
            <div class="reader__workspace__analysis--annotation-container flex flex-col text-lg">
              <div v-show="annotation.render_referenced_elements == 'true'" v-html="annotation.referenced_text.trim()" class="font-serif font-bold p-1"></div>
              <div class="flex flex-row justify-start items-center p-1">

                <hermeneus-markdown-wrapper :markdown="annotation.annotation_text" class="inline-block"></hermeneus-markdown-wrapper>
                <button @click="pin(annotation, annotation.line)" class="btn-pin ml-4 text-grey-500 hover:text-secondary-600">
                  <i class="hi hi-pin-round "></i>
                </button>
              </div>
            </div>

          </div>
        </div>


      </div>

    </div>
  </transition>
  <!--<div id="reader__workspace__analysis&#45;&#45;annotation"
       class="reader__workspace__analysis-category flex flex-row items-start justify-between h-full  border border-annotation-500 p-2 rounded shadow">
    <hermeneus-icon name="hi-annotation-min" height="35" width="35" color="hi-color-annotation-300" class=" mr-2"></hermeneus-icon>
    <div v-for="annotation in this.annotationanalysis"
         @mouseover="highlight(annotation)"
         @mouseout="dehighlight(annotation)"
         class="w-full">
      <div class="reader__workspace__analysis&#45;&#45;annotation-container text-lg px-2">
        <vue-markdown :source="annotation.annotation_text" class="inline-block"></vue-markdown>
        <button @click="pin(annotation, annotation.line)" class="btn-pin ml-4 text-grey-500 hover:text-secondary-600">
          <i class="hi hi-pin-round "></i>
        </button>
      </div>

    </div>

    <button @click="close('AnnotationAnalysis')" class="text-annotation-500 hover:text-annotation-700 p-2 pt-3">
      <i class="hi hi-close-light"></i>
    </button>


  </div>
-->
</template>

<script>
import {EventBus} from "../../../hermeneus.eventbus/eventbus";
export default {
  name: "reader-annotation-analysis",
  props: ['annotationanalysis'],
  components: {
  },
  data: function () {
    return {
      LoadingStatus: false,
    }
  },
  methods: {
    close (AnalysisType) {
      EventBus.$emit('set' + AnalysisType, false);
    },
    highlight (Annotation) {
      for (let ReferencedElement of Annotation.referenced_elements) {
        ReferencedElement.classList.add('reader__highlight');
      }
    },
    dehighlight (Annotation) {
      for (let ReferencedElement of Annotation.referenced_elements) {
        ReferencedElement.classList.remove('reader__highlight');
      }
    },
    /**
     *
     * @param annotation
     * @param line
     */
    pin (annotation, line) {
      EventBus.$emit('addAnnotationNote', {
        annotation_text: annotation.annotation_text,
        type: annotation.type,
        render_referenced_elements: annotation.render_referenced_elements,
        referenced_text: annotation.referenced_text,
        referenced_elements: annotation.referenced_elements,
        element: annotation.element,
        line: line
      });

    }
  }
}
</script>

<style scoped>


</style>