<template>
  <div id="reader_wrapper" :class="`
  stylesheet-${data.config.stylesheet ? data.config.stylesheet : 'hermeneus'}`">
    <reader-header></reader-header>
    <transition name="slideY">
      <reader-introduction v-if="ShowReaderIntroduction"></reader-introduction>
    </transition>
    <div id="reader">
<!--      <Transition name="animation_sentenceviewer">-->
        <reader-sentence-viewer v-if="SentenceAnalysis" :sentenceanalysis="SentenceAnalysis"></reader-sentence-viewer>
<!--      </Transition>-->
<!--      <transition name="animation_sentenceviewer">
        <reader-verse v-if="VerseAnalysis" ref="verse" :verseanalysis="VerseAnalysis"></reader-verse>

      </transition>-->
      <reader-edition-info :header="Textparts.Header"></reader-edition-info>
      <reader-titleinfo :header="Textparts.Header"></reader-titleinfo>

      <div id="reader__workspace">
        <reader-workspace ref="workspace" :body="Textparts.Body"></reader-workspace>
        <reader-ad-lineam :annotationnotes="AnnotationNotes" :lines="Lines" :wordnotes="WordNotes"></reader-ad-lineam>
      </div>

      <div id="reader__workspace__analysis" class="md-minus:px-3 md-minus:pb-3 lg-plus:px-6 lg-plus:pb-6">
        <div class="flex md-minus:flex-col lg-plus:flex-row justify-between items-stretch shadow-lg">

          <reader-annotation-analysis :annotationanalysis="AnnotationAnalysis" class="flex-1 "></reader-annotation-analysis>
          <reader-vocab-analysis :wordanalysis="WordAnalysis" class="flex-1"></reader-vocab-analysis>
        </div>
      </div>


    </div>
    <reader-sub-lineam :annotationnotes="AnnotationNotes" :lines="Lines" :wordnotes="WordNotes"></reader-sub-lineam>
    <reader-footer></reader-footer>
  </div>
</template>

<script>
import Reader from "../reader.class_reader";
import ReaderWorkspace from "./ReaderWorkspace.vue";
import ReaderTitleinfo from "../components/ReaderTitleInfo.vue"
import ReaderIntroduction from "../components/ReaderIntroduction.vue"
import TextPreparation from "../reader.class_textpreparation";
import {EventBus} from '../../../hermeneus.eventbus/eventbus'
import ReaderSentenceViewer from "./ReaderSentenceViewer/ReaderSentenceViewer.vue"
import ReaderAdLineam from "./ReaderAdLineam.vue"
import ReaderSubLineam from "./ReaderSubLineam.vue"
import HermeneusFeedback from "../../../hermeneus.feedback/HermeneusFeedback.vue"
import ReaderVocabAnalysis from "./ReaderVocabAnalysis/ReaderVocabAnalysis.vue"
import ReaderAnnotationAnalysis from "./ReaderAnnotationAnalysis.vue"
import {DemoTourMixin} from "@/mixins/DemoTour.mixin.js";
import ReaderHeader from "./ReaderHeader/ReaderHeader.vue"
import ReaderFooter from "./ReaderFooter/ReaderFooter.vue"
import QuerySelectors from "../reader.class_queryselectors.js";
import ReaderEditionInfo from "./ReaderEditionInfo.vue";

export default {
  name: "hermeneus-reader",
  props: ['data'],
  mixins: [DemoTourMixin],
  components: {
    'reader-edition-info':ReaderEditionInfo,
    'hermeneus-feedback': HermeneusFeedback,
    'reader-header': ReaderHeader,
    'reader-introduction': ReaderIntroduction,
    'reader-workspace': ReaderWorkspace,
    'reader-footer': ReaderFooter,
    'reader-titleinfo': ReaderTitleinfo,
    'reader-annotation-analysis': ReaderAnnotationAnalysis,
    'reader-sentence-viewer': ReaderSentenceViewer,
    /*'reader-verse': ReaderVerse,*/
    'reader-vocab-analysis': ReaderVocabAnalysis,
    'reader-ad-lineam': ReaderAdLineam,
    'reader-sub-lineam': ReaderSubLineam,
  },
  computed: {
    hasVocabAndAnnotationAnalysis: function () {
      return (this.AnnotationAnalysis !== false && this.AnnotationAnalysis.length > 0) && (this.WordAnalysis !== false)
    },

    hasSteps: function () {
      return Object.keys(this.Steps).length > 0;
    },
    hasAnswers: function () {
      return Object.keys(this.Answers).length > 0;
    },
    hasFarben: function () {
      return Object.keys(this.Farben).length > 0;
    },
    hasSubjPred: function () {
      return Object.keys(this.SubjPred).length > 0;
    },
  },
  data: function () {
    return {
      Annotations: [],
      AnnotationAnalysis: [],
      AnnotationNotes: [],
      Farben: [],
      Highlights: [],
      Answers: [],
      AnswersAreEnabled: false,
      Lines: [],
      Sentences: [],
      MouseButtonPressed: window.MouseButtonIsPressed,
      PrintDoubleSpacing: false,
      SentenceAnalysis: '',
      SentenceActiveID: null,
      ShowReaderIntroduction: this.data.config.intro,
      Steps: {},
      SubjPred: [],
      Tool_SubjPred_Show: false,
      Tool_SubjPred_Highlight: false,
      Textparts: TextPreparation.parseTEISource(this.data.text, this.data.config),
      Tools: [],
      VerseAnalysis: '',
      WordAnalysisLoading: false,
      WordAnalysis: false,
      WordAnalysisShowMorpho: true,
      WordNotes: [],
    }
  },
  mounted: async function () {
    this.Reader = new Reader(this, this.data.text, this.data.config, this.data.textdata);
    this.Lines = TextPreparation.getLines(this.Textparts.Source);
    EventBus.$on('addWordNote', Value => {
      this.Reader.addWordNote(Value);
    });
    EventBus.$on('addAnnotationNote', Value => {
      this.Reader.addAnnotationNote(Value);
    });
    EventBus.$on('setSentenceAnalysis', Value => {

      this.$data.SentenceAnalysis = Value;
    });
    EventBus.$on('setSentenceActiveID', Value => {
      this.$data.SentenceActiveID = Value;
    });
    EventBus.$on('openSentenceByID', async Value => {
      this.$data.SentenceActiveID = Value;
      let SentenceNo = this.$data.SentenceAnalysis = this.Sentences.find(Sentence => String(Sentence.sentence_id) === String(Value)).sentence_n;
      this.$data.SentenceAnalysis = document.querySelectorAll(`${QuerySelectors.sentence}[n="${SentenceNo}"]`)[0].innerHTML;
    });
    EventBus.$on('setVerseAnalysis', Value => {
      this.$data.VerseAnalysis = Value;
    });

    EventBus.$on('setWordAnalysis', Value => {
      this.$data.WordAnalysis = Value;
    });
    EventBus.$on('refreshWordAnalysisFromAPI', Value => {
      this.Reader.analyze(Value);
    });
    EventBus.$on('hideAllFarben', () => {
      this.Reader.hideAllFarben();
    });
    EventBus.$on('setAnnotationAnalysis', Value => {
      this.$data.AnnotationAnalysis = Value;
    });

    /**
     *
     */
    EventBus.$on('registeredSentence', Value => {
      this.$data.SentenceAnalysis = Value.SentenceElement.innerHTML;
    });
    EventBus.$on('removeWordNote', Value => {
      this.Reader.removeWordNote(Value);
    });
    EventBus.$on('removeAnnotationNote', Value => {
      this.Reader.removeAnnotationNote(Value);
    });
    EventBus.$on('openReaderIntroduction', () => {
      this.ShowReaderIntroduction = true;
    });
    EventBus.$on('closeReaderIntroduction', () => {
      this.ShowReaderIntroduction = false;
    });
    EventBus.$on('toggleShowFarbe', farbe => {
      this.Reader.toggleShowFarbe(farbe);
    });

    EventBus.$on('toggleWordAnalysisShowMorpho', () => {
      this.WordAnalysisShowMorpho = !this.WordAnalysisShowMorpho;
    });

    EventBus.$on('toggleShowHighlight', highlight => {
      this.Reader.toggleShowHighlight(highlight);
    });
    EventBus.$on('highlightHighlight', highlight => {
      this.Reader.highlightHighlight(highlight);
    });
    EventBus.$on('dehighlightHighlight', highlight => {
      this.Reader.dehighlightHighlight(highlight);
    });

    EventBus.$on('toggleOnWorkspaceSubjPred', () => {
      this.Tool_SubjPred_Show = true;
      this.Reader.SubjPred_On();
    });
    EventBus.$on('toggleOffWorkspaceSubjPred', () => {
      this.Tool_SubjPred_Show = false;
      this.Reader.SubjPred_Off();
    });
    EventBus.$on('setToolSubjPredHighlight', (boolean) => {
      this.Tool_SubjPred_Highlight = boolean;
    });


  },
}
</script>

<style scoped>


</style>