<template>
    <div v-show="this.active" >
        <div class="flex flex-row justify-start items-center text-black" :class="{'w-full bg-white p-4': active}">
            <button :disabled="SatzhilfeUnavailable" @click="getSatzhilfe" @mouseover="hermesMouseover=true" @mouseout="hermesMouseover=false"
                    class="tool-satzhilfe__btn-hermes flex flex-col items-center h-auto  w-40 p-4  rounded-lg bg-primary-200 hover:bg-primary-100 active:bg-primary-300 shadow-md">

                <hermeneus-icon name="hi-hermes-head-2c" height="90" width="90"></hermeneus-icon>
                <div class="tool-satzhilfe__btn-hermes__desc flex break-all font-bold text-4xl mt-2 text-primary-800">
                    <i class="fas fa-battery-full text-success " v-if="$userData.tokens === 5"></i>
                    <i class="fas fa-battery-full  " v-else-if="$userData.tokens === 4"></i>
                    <i class="fas fa-battery-three-quarters  " v-else-if="$userData.tokens === 3"></i>
                    <i class="fas fa-battery-half  " v-else-if="$userData.tokens === 2"></i>
                    <i class="fas fa-battery-quarter" v-else-if="$userData.tokens === 1"></i>
                    <i class="fas fa-battery-empty text-danger" v-else-if="$userData.tokens === 0"></i>
                </div>
            </button>
            <transition-group name="bounce" >

                <div key="1" v-if="isHermesLoading" class="ml-8">
                    <loading-dots style="transition-delay: 1.5s"></loading-dots>
                </div>
                <div key="2" v-if="!isHermesLoading&&satzhilfe.length>0" v-html="satzhilfe"
                     class="whitespace-pre leading-loose p-4 tool-satzhilfe__btn-hermes__speech-bubble flex bg-primary-100 text-lg rounded-lg h-full w-full shadow-md ml-8">

                </div>
            </transition-group>

        </div>
    </div>
</template>

<script>
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";
import axios from "axios";

export default {
    name: "reader-sentence-viewer-tool-satzhilfe",
    components: {},
    props: {name: {required: true}},
    data: function () {
        return {
            active: this.$parent.$parent.Tool_Satzhilfe_Active,
            btn_img_src: '/img/svg/hi-hermes-head-2.svg',
            btn_hover_classes: '',
            btn_disabled_if: false,
            btn_hidden_if: false,
            btn_infobar_html: `Satzhilfe`,
            btn_html: `Satzhilfe`,
            satzhilfe: '',
            hermesMouseover: false,
            isHermesLoading: false,
        }

    },
    computed: {
        Sentence: function () {
            return this.$parent.$parent.$parent.Sentences.find(Sentence => parseInt(Sentence.sentence_id) === parseInt(this.$parent.$parent.$parent.SentenceActiveID));

        },
        SatzhilfeUnavailable: function () {
            if(!this.Sentence) {
                return true;
            }
            if(this.$userData.tokens === 0) {
                return this.$userData.role === 'user' || this.$userData.role === 'teacher';

            }
            return false;
        }
    },
    methods: {
        async activate () {

        },

        async getSatzhilfe () {
            this.isHermesLoading = true;
            axios.post('/api/get-satzhilfe', {
                sentence: await this.getSentenceString(),
                context: await this.getTextpartString()
            })
                 .then((response) => {
                     this.isHermesLoading = false;
                     this.satzhilfe = response.data.satzhilfe;
                     this.$root.getUserData();
                 })
                 .catch((error) => {
                     console.log(error);

                 });
        },
        deactivate () {
        }
        ,
        async getSentenceString () {
            return await this.Sentence.sentence_string;
        }
        ,
        async getTextpartString () {
            return this.$parent.$parent.$parent.Textparts.TextContent;
        }
    }
}
</script>

<style scoped>
.tool-satzhilfe__btn-hermes {
    transition: all 0.3s ease-in-out;

}

.tool-satzhilfe__btn-hermes__desc {
    transition: all 0.3s ease-in-out;
}

.tool-satzhilfe__btn-hermes__speech-bubble {
    height: 100%;
}


.bounce-enter-active {
    animation: bounce-in 0.3s ease-in-out;
}

.bounce-leave-active {
    animation: bounce-in 0.25s reverse;
}

@keyframes bounce-in {
    0% {
        opacity: 0;
        transform: scale(0.3) translate3d(0, 0, 0);
    }
    50% {
        opacity: 0.9;
        transform: scale(1.1);
    }
    80% {
        opacity: 1;
        transform: scale(0.89);
    }
    100% {
        opacity: 1;
        transform: scale(1) translate3d(0, 0, 0);
    }
}
button[disabled] {
    opacity: 0.5;
}
</style>