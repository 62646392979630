<template>
  <div  class="reader__sentence-viewer__answer">
    <div class="border border-success bg-success_light rounded p-4 text-lg italic flex flex-col" >
      <div class="flex flex-row justify-start items-center"> <hermeneus-icon name="hermeneus-btn-answers" height="24" width="24" class="mr-2"></hermeneus-icon><span class="font-bold text-success">Musterlösung</span></div>
      <div v-html="answer.answertext" class="mt-3"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['answer'],
  name: "reader-sentence-viewer-answer"
}
</script>

<style scoped>

</style>