import {EventBus} from "../hermeneus.eventbus/eventbus";
import {wait} from "../helpers/functions_helpers";

/**
 * SHEPHERD-VUE
 * @type {{mounted: ((function(): Promise<void>)|*)}}
 */
export let DemoTourMixin = {

    mounted: async function () {

        /**
         * DEMO MODE
         */
        if (this.data.hasOwnProperty('demo') && this.data.demo === 'true') {

            this.$nextTick(() => {
                const tour = this.$shepherd({
                    keyboardNavigation: false,
                    useModalOverlay: true
                });

                tour.addStep({

                    text: `<span class="font-bold">Herzlich willkommen zum Hermeneus-Reader!</span><br><br>

                          In dieser Einführung werden Sie die wesentlichen Funktionen des Readers kennenlernen.
                           Kurzer Hinweis: Immer wenn Sie dieses Icon sehen (<i class="far fa-hand-pointer text-secondary_color text-xl"></i>), können Sie mit der Seite interagieren.<br><br>
                           Klicken Sie auf Weiter, um fortzufahren.`,
                    buttons: [
                        {
                            text: 'Weiter',
                            action: tour.next
                        }
                    ]
                });
                tour.addStep({

                    text: `Der Hemeneus-Reader ist eine <span class="font-bold">Leseumgebung für lateinische Texte</span>, die von Ihnen oder anderen NutzerInnen aufbereitet wurden.
                                             <br><br>
                                              Mit dem Hermeneus-Reader können ...
                                                <ul class="list-disc list-inside">
                                                <li>Lehrkräfte einen Text im Präsenz- oder Distanzunterricht für ihre Schülerinnen und Schüler visualisieren und vorerschließen und  </li>
                                                <li>Schülerinnen und Schüler einen Text selbständig mit zahlreichen Hilfestellungen erschließen.</li>
                                                </ul><br>
                                                Im Zentrum steht aber immer der lateinische Text!
                                                `,
                    buttons: [
                        {
                            text: "Los geht's!",
                            action: tour.next
                        }
                    ]
                });
                tour.addStep({

                    text: 'Oberhalb des Textes finden Sie alle nötigen Angaben zum Text: Autor und Textstelle, Titel der Textstelle und einen kleinen Einführungstext.',
                    attachTo: {
                        element: '#reader__titleinfo',
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Weiter',
                            action: tour.next
                        }
                    ]
                });
                tour.addStep({

                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Klicken Sie nun auf das Wort, um es im Wörterbuch (Glossarium) nachzuschlagen und einige Informationen darüber zu erhalten.`,
                    classes: 'add-margin-top',
                    attachTo: {
                        element: 'tei-w[w_id="51"]',
                        on: 'bottom'
                    }
                });
                tour.addStep({
                    text: `Hier werden die <span class="font-bold">Wortangaben</span> eingeblendet. Es werden alle Lemmata angezeigt, von denen die Form <strong>amare</strong> abstammen kann. Dies lässt sich aber im Editor anpassen.
                <br><br>
                Dieses Fenster bietet auch noch weitere Funktionen.`,
                    attachTo: {
                        element: '.reader__analysis-vocab__body',
                        on: 'top'
                    },
                    canClickTarget: false,
                    buttons: [
                        {
                            text: 'Aha, welche denn?',
                            action: tour.next
                        }
                    ]
                })
                tour.addStep({
                    text: '<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Mit diesem Button lässt sich jedes Wort rechts vom Text <span class="font-bold">als ad-lineam-Kommentar anpinnen</span>. Sie können sozusagen Ihre eigene Lektüreausgabe erstellen.<br> Auf kleineren Bildschirmen wandert der ad-lineam-Kommentar übrigens automatisch unter den Text.',
                    attachTo: {
                        element: '.btn-pin',
                        on: 'right'
                    },
                    classes: 'add-margin-left',

                })
                tour.addStep({
                    text: `Prima! <br><br> <i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Mit Klick auf diese Tabelle können Sie morphologische Angaben aus- und einblenden.`,
                    attachTo: {
                        element: '#demo-tour__showMorpho',
                        on: 'bottom'
                    },
                    classes: 'add-margin-bottom',
                    buttons: [
                        {
                            text: 'Weiter',
                            action: tour.next
                        }
                    ]
                })
                tour.addStep({
                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Indem Sie auf das Lemma klicken, können Sie außerdem den Eintrag im Glossarium aufrufen, der weitere Wortinformationen enthält.`,
                    attachTo: {
                        element: '.vocab-analysis__lemma_amare',
                        on: 'top'
                    },
                    classes: 'add-margin-bottom',

                })
                tour.addStep({
                    text: `Hier sehen Sie einige Basisinformationen und die Deklinations- bzw. Konjugationstabellen.`,
                    classes: 'shepherd-center-top',
                    buttons: [
                        {
                            text: 'Weiter',
                            action: tour.next
                        }
                    ]

                })
                tour.addStep({
                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Mit Klick auf "Wortbausteine" kann die Wortbildung der Form illustriert werden.`,
                    attachTo: {
                        element: '.vocab-viewer__wortbausteine',
                        on: 'bottom'
                    }

                })
                tour.addStep({
                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Gehen wir nun zurück zum Text.`,
                    classes: 'add-margin-right',
                    attachTo: {
                        element: 'button .hi-close',
                        on: 'right'
                    }

                })
                tour.addStep({

                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Klicken Sie nun auf die Form <strong>Quod</strong>, welche erwartungsgemäß mehrere Bedeutungen hat.`,
                    classes: 'add-margin-top',
                    attachTo: {
                        element: 'tei-w[w_id="46"]',
                        on: 'bottom'
                    }
                });
                tour.addStep({

                    text: `Der grüne Haken signalisiert uns, um welches Lemma es sich handelt und wie die Form zu übersetzen ist. Dies kann bei Bedarf manuell im Editor festgelegt werden.
                                    <br> <br>Lassen Sie uns nun unser Augenmerk auf die rosa hinterlegten Wörter lenken.`,
                    classes: 'add-margin-bottom',
                    attachTo: {
                        element: '.reader__analysis-vocab__body .reader__analysis-vocab__body__lemma-check',
                        on: 'top'
                    },
                    buttons: [
                        {
                            text: 'Okay!',
                            action: tour.next
                        }
                    ]
                });
                tour.addStep({
                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Rosa hinterlegte Wörter beinhalten freie Anmerkungen, die beim Übersetzen helfen können.`,
                    classes: 'add-margin-top',
                    attachTo: {
                        element: 'tei-w[corresp="annotation1"]',
                        on: 'bottom'
                    },


                })
                tour.addStep({
                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Auch diese können natürlich an den Text angepinnt werden.`,
                    classes: 'add-margin-left',
                    id: 'pin_comment',
                    attachTo: {
                        element: '#reader__workspace__analysis--annotation .btn-pin',
                        on: 'right'
                    },


                })
                tour.addStep({
                    text: `Das klappt ja wunderbar! Lassen Sie uns nun die Subjekte und Prädikate des Textes betrachten.`,

                    buttons: [
                        {
                            text: 'Sehr gerne!',
                            action: tour.next
                        }
                    ]
                })
                tour.addStep(
                    {
                        text: `Aktivieren Sie hierzu den Subjekt-Prädikat-Modus.`,
                        attachTo: {
                            element: '.button-footer-subj-pred',
                            on: 'top'
                        }
                    },
                )
                tour.addStep(
                    {
                        text: `Sie sehen bei einigen Wörtern unterschiedliche Unterstreichungen. Diese bedeuten folgendes: <br><br>
                        <div class="expl-subj inline-block h-8 mr-2">Subjekt</div>
                        <div class="expl-pred inline-block h-8 mr-2">Prädikat</div>
                        <div class="expl-subj-pred inline-block h-8">Subjekt im Prädikat</div>
`,

                        buttons: [
                            {
                                text: 'Verstanden!',
                                action: tour.next
                            }
                        ]
                    },
                )
                tour.addStep(
                    {
                        text: `Klicken Sie jetzt auf <strong>cogit</strong>, um das zugehörige Subjekt anzuzeigen.`,
                        id: 'click_on_a_subjekt',
                        classes: 'add-margin-bottom',
                        attachTo: {
                            element: 'tei-w[w_id="50"]',
                            on: 'top'
                        },
                    },
                )
                tour.addStep(
                    {
                        text: `Es wird jetzt das entsprechende Subjekt <strong>iniuria</strong> hervorgehoben.`,
                        id: 'click_on_a_subjekt',
                        classes: 'add-margin-bottom',
                        attachTo: {
                            element: 'tei-w[w_id="48"]',
                            on: 'top'
                        },
                        buttons: [
                            {
                                text: 'Weiter',
                                action: tour.next

                            }
                        ],
                    },
                )
                tour.addStep(
                    {
                        text: `Bei einem Klick auf ein Prädikat werden <span class="font-bold"> alle dazugehörigen Subjekte hervorgehoben</span>. Dies funktioniert bei einem Klick auf ein Subjekt genau umgekehrt. <br><br>
                                Probieren Sie gerne ein wenig herum.`,
                        classes: 'add-margin-bottom',
                        attachTo: {
                            element: '#reader__workspace__primary--text',
                            on: 'top'
                        },
                        buttons: [
                            {
                                text: 'Ich bin fertig.',
                                action: tour.next

                            }
                        ],
                        when: {
                            show: function () {
                                document.querySelectorAll('.reader__hermeneus-helper--button-sentence-number').forEach(SentenceButton => {
                                    SentenceButton.setAttribute('disabled', null);
                                })
                                document.querySelectorAll('div.reader__workspace__primary--linenumber-container').forEach(VerseButton => {
                                    VerseButton.style.pointerEvents = "none";

                                })

                            },
                            hide: function () {
                                document.querySelectorAll('.reader__hermeneus-helper--button-sentence-number').forEach(SentenceButton => {
                                    SentenceButton.removeAttribute('disabled');
                                })
                                document.querySelectorAll('div.reader__workspace__primary--linenumber-container').forEach(VerseButton => {
                                    VerseButton.style.pointerEvents = "auto";
                                })
                                EventBus.$emit('toggleOffWorkspaceSubjPred');
                            }
                        }
                    },
                )
                tour.addStep({
                    text: `Wollen wir uns nun den Farbmarkierungen widmen: <br> <br>
In diesen Text sind vier Farbunterstreichungen zu bestimmten Sachfeldern eingearbeitet. Diese überschneiden sich teilweise.`
                    ,
                    buttons: [
                        {
                            text: 'Weiter',
                            action: tour.next
                        }
                    ]


                })

                tour.addStep({
                    text: `Aktivieren Sie nun die Farbmarkierungen mittels der Kästchen und achten Sie dabei auf den Text.`
                    ,
                    attachTo: {
                        element: '.buttons-footer-farben',
                        on: 'top'
                    },
                    classes: 'add-margin-bottom',
                    buttons: [
                        {
                            text: 'Ich bin fertig.',
                            action: tour.next
                        }
                    ]


                })
                tour.addStep({
                    text: `Wie Sie gesehen haben, <span class="font-bold">sind die Farbmarkierungen additiv</span>. So lassen sich z.B. auch grammatikalische Phänomene oder stilistische Besonderheiten im Text abbilden.
<br><br>
Sie finden diese beiden Werkzeuge übrigens auch hier oben in der Kopfleiste.`
                    ,
                    classes: 'add-margin-bottom',
                    attachTo: {
                        element: '.hermeneus-textinfo__top-bar',
                        on: 'top'
                    },
                    canClickTarget: false,
                    buttons: [
                        {
                            text: 'Verstanden.',
                            action: tour.next
                        }
                    ]


                })
                tour.addStep({
                    text: `Sind Ihnen bereits die kleinen Nummern hinter den Sätzen aufgefallen? <br> Damit wird die Satzansicht geöffnet.`
                    ,
                    buttons: [
                        {
                            text: 'Das will ich sehen!',
                            action: tour.next
                        }
                    ]


                })
                tour.addStep({
                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Klicken Sie nun auf Satz 3, um die Satzansicht zu öffnen.`,
                    classes: 'add-margin-top',
                    id: 'open_sentence',
                    attachTo: {
                        element: 'button[sentence-no="3"]',
                        on: 'bottom'
                    },


                })

                tour.addStep({
                    text: `In der Satzansicht gibt es eine kolometrische Satzanalyse, in der die Nebensätze eingerückt sind. <br><br>

<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Auch hier lassen sich die Wörter anklicken und die Bedeutungen anzeigen. Dies eignet sich besonders für den Einsatz im Präsenzunterricht, wo Wörter häufig an der Tafel angeschrieben werden müssen. Probieren Sie es gerne ein bisschen aus.`,
                    classes: 'add-margin-top',
                    beforeShowPromise: async function () {
                        await wait(500);
                    },
                    attachTo:
                        {
                            element: '.reader-analysis #reader__workspace__analysis--sentence_container',
                            on: 'bottom'
                        }
                    ,
                    buttons: [
                        {
                            text: 'Ich bin fertig.',
                            action: tour.next
                        }
                    ]


                })
                tour.addStep({
                    text: `Die <span class="font-bold">Farbmarkierungen</span> und <span class="font-bold">Subjekte und Prädikate</span> können Sie auch in der Satzansicht einblenden. In der Satzansicht lassen sich außerdem die <span class="font-bold">Satzschritte</span> aktivieren.`,
                    classes: 'add-margin-top',
                    attachTo:
                        {
                            element: '#reader__workspace__analysis--sentence__toolbar',
                            on: 'top'
                        },

                    canClickTarget: false,
                    buttons: [
                        {
                            text: 'Was ist das?',
                            action: tour.next
                        }
                    ]


                })

                tour.addStep({
                    text: `Damit werden bestimmte Teile eines Satzes <span class="font-bold">Schritt für Schritt</span> eingeblendet. So können Lehrkräfte Ihre Klasse durch den Satz führen oder Schülerinnen und Schüler können den Aufbau eines Satzes beim selbständigen Übersetzen besser nachvollziehen.`,
                    classes: 'add-margin-top',
                    attachTo:
                        {
                            element: '#reader__workspace__analysis--sentence__toolbar',
                            on: 'top'
                        }
                    ,
                    canClickTarget: false,
                    buttons: [
                        {
                            text: 'Das ist ja Wahnsinn!',
                            action: tour.next
                        }
                    ]
                })

                tour.addStep({
                    text: `Ich weiß. 😉 <br><br>
                    <i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Lassen Sie uns das Werkzeug einmal ausprobieren, indem Sie es aktivieren.`,
                    classes: 'add-margin-bottom',
                    id: 'activate_step-tool',
                    attachTo:
                        {
                            element: '.sentence-viewer__panel--button.SentenceViewerSteps',
                            on: 'top'
                        }

                })

                tour.addStep({
                    text: `Sie können mit der Maus oder den Pfeiltasten durch den Satz navigieren.`,
                    classes: 'add-margin-bottom',
                    attachTo:
                        {
                            element: '.reader__workspace__analysis--sentence__toolbar-container',
                            on: 'top'
                        },
                    buttons: [
                        {
                            text: 'Ich bin fertig.',
                            action: tour.next
                        }
                    ]
                })
                tour.addStep({
                    text: `Kehren wir nun wieder zum Text zurück.`,
                    classes: 'add-margin-top',
                    id: 'close_sentence',
                    attachTo:
                        {
                            element: '.reader__header--close-button',
                            on: 'bottom'
                        }
                })
                tour.addStep({
                    text: `In Dichtungstexten lassen sich auch die einzelnen Verse anklicken und das Versmaß bestimmen.`,

                    buttons: [
                        {
                            text: 'Weiter',
                            action: tour.next
                        }
                    ]
                })
                tour.addStep({
                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i> Sehen wir uns Vers 1 mal etwas genauer an.`,
                    classes: 'add-margin-top',
                    id: 'show_verse_viewer',
                    attachTo:
                        {
                            element: '.reader__workspace__primary--linenumber-container',
                            on: 'bottom'
                        },
                })
                tour.addStep({
                    text: `Hermeneus analysiert automatisch alle Hexameter und Pentameter. Sollte dies einmal nicht funktionieren, können Sie in der Versansicht die Quantitäten der Vokale verändern und den Vers neu analysieren lassen.`,
                    classes: 'add-margin-top',
                    attachTo:
                        {
                            element: '.scansion-container',
                            on: 'bottom'
                        },
                    buttons: [
                        {
                            text: 'Alles klar!',
                            action: tour.next
                        }
                    ]
                })
                tour.addStep({
                    text: `<i class="far fa-hand-pointer text-secondary_color text-2xl mr-2"></i>Sie können das gerne ein bisschen ausprobieren. Wenn Sie fertig sind, gehen wir wieder zurück zum Text.`,
                    classes: 'add-margin-top',
                    attachTo:
                        {
                            element: '#reader__workspace__analysis--verse_container',
                            on: 'auto'
                        },
                    buttons: [
                        {
                            text: 'Ich bin fertig.',
                            action: tour.next
                        }
                    ],
                    when: {
                        hide: async function () {
                            EventBus.$emit('setVerseAnalysis', null)
                        }
                    }
                })
                tour.addStep({
                    text: `Hinter diesen drei Buttons verbergen sich folgende Funktionen.
                    <div class="my-2 leading-loose">
                    <img src="/img/svg/hi-comment.svg" height="18" width="18" class="inline-block mr-2"/>
                    Eingeloggte Nutzerinnen oder Nutzer können einen Text hier kommentieren oder Fragen dazu stellen.
                    </div>
                    <div class="my-2 leading-loose">
                                        <i class="fas fa-question text-lg mr-2"></i>
                    Hiermit werden allgemeine Hinweise zur Bedienung des Hermeneus-Readers angezeigt.
                    </div>
                    <div class="my-2 leading-loose">
                    <i class="fas fa-file text-lg mr-2"></i>
                    Druck oder PDF-Funktion: Hier haben Sie die Möglichkeit, den Text (ggf. mit den ad-lineam-Angaben) auszudrucken oder als PDF herunterzuladen. Sie können außerdem den Zeilenabstand vergrößern. 
                    </div>`,
                    classes: 'add-margin-top',
                    canClickTarget: false,
                    attachTo:
                        {
                            element: '.reader-header__buttons',
                            on: 'bottom'
                        }, buttons: [
                        {
                            text: 'Verstanden.',
                            action: tour.next
                        }
                    ],
                })
                tour.addStep({
                    text: `
                    <h1 class="text-xl">Herzlichen Glückwunsch!</h1>
                    <div>Sie haben die Einführung jetzt abgeschlossen. Wenn Sie möchten, können Sie den Text jetzt selbst frei erkunden. Oder noch besser: Sie bereiten gleich selbst einen Text für den Hermeneus-Reader auf!</div>
                    `,
                    classes: 'add-margin-top',
                    buttons: [
                        {
                            text: '👋 Tour beenden',
                            action: tour.complete
                        }
                    ],
                })

                tour.start();

                EventBus.$on('addWordNote', Value => {
                    tour.next();

                });
                // Kommentar wird angepinnt
                EventBus.$on('addAnnotationNote', async (Value) => {
                    if (tour.getCurrentStep().id === 'pin_comment') {
                        EventBus.$emit('setWordAnalysis', false);
                        EventBus.$emit('setVerseAnalysis', false);
                        EventBus.$emit('setAnnotationAnalysis', false);
                        await wait(500);
                        tour.next();

                    }

                });
                EventBus.$on('wordClicked', Value => {
                    if (Value.textContent === 'amare') {
                        tour.next();
                    }

                });
                EventBus.$on('wordClicked', Value => {
                    if (Value.textContent === 'tenere') {
                        tour.next();
                    }

                });
                EventBus.$on('wordClicked', Value => {
                    if (Value.textContent === 'Quod') {
                        tour.next();
                    }

                });
                EventBus.$on('toggleOnWorkspaceSubjPred', Value => {
                    tour.next();

                });
                EventBus.$on('setToolSubjPredHighlight', Value => {
                    if (tour.getCurrentStep().id === 'click_on_a_subjekt') {
                        tour.next();

                    }

                });
                EventBus.$on('SentenceOpened', Value => {
                    if (tour.getCurrentStep().id === 'open_sentence') {
                        tour.next();

                    }

                });
                EventBus.$on('modal_content_loaded', Value => {
                    tour.next();

                });
                EventBus.$on('CloseModalEvent', Value => {
                    tour.next();

                });
                EventBus.$on('WortbausteineActivated', Value => {
                    tour.next();

                });
                EventBus.$on('SentenceViewerStepsActivated', Value => {
                    if (tour.getCurrentStep().id === 'activate_step-tool') {
                        tour.next();
                    }
                });
                EventBus.$on('setSentenceAnalysis', Value => {
                    if (tour.getCurrentStep().id === 'close_sentence') {
                        tour.next();
                    }
                });

                EventBus.$on('setVerseMeter', Value => {
                    if (tour.getCurrentStep().id === 'show_verse_viewer') {
                        tour.next();
                    }
                });

            });
        }

    }
}