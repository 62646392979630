<template>
    <div id="reader__sub-lineam" class="p4 text-sm">
        <div
                v-for="line in lines"
                class="reader__notes__line-container w-auto flex flex-row flex-wrap flex-shrink justify-start"
                :id="'line_' + line.line_no"
                ref="linecontainers"
        >
            <div v-if="hasNote(line)" v-html="line.line_no" class="font-bold text-serif mr-2"></div>
            <!--WORDNOTES-->
            <div v-for="(wordnote, key, index) in filteredWordNotes(line)"
                 @click="removeWordNote(wordnote)"
                 :id="'wordnote_' + key"
                 class="flex flex-row justify-start flex-wrap hover-crossed hover-secondary cursor-pointer">
                <div class="reader__notes__note-lemma text-serif bold black">{{wordnote.lemma.lemma}}</div>
                <div class="reader__notes__note-info text-serif black">{{wordnote.lemma.info}}</div>
                <div class="reader__notes__note-bedeutung">: {{wordnote.lemma.bedeutung}}</div>
                <div class="reader__notes__note-divider"></div>
            </div>


            <!--ANNOTATIONNOTES-->
            <span
                    class="reader__notes__note-container mr-2 hover-crossed hover-secondary cursor-pointer"
                    :class="'type-' + annotationnote.type"
                    @click="removeAnnotationNote(annotationnote)"
                    v-for="(annotationnote, key, index) in filteredAnnotationNotes(line)"
                    :id="'annotationnote_' + key"
                   >
                                <span v-if="annotationnote.render_referenced_elements != 'false'" class="text-serif bold reader__notes__annotation-reference">{{annotationnote.element.textContent}}: </span>
                                <hermeneus-markdown-wrapper :markdown="annotationnote.annotation_text" class="inline-block"></hermeneus-markdown-wrapper>
                            </span>

        </div>

    </div>

</template>

<script>
    import HasNotes from "./HasNotes.vue";
    import ReaderFooterToolbar from "./ReaderFooter/ReaderFooterToolbar.vue";
    export default {
        extends: HasNotes,
        name: "reader-sub-lineam",

      setup (props) {
        const filteredWordNotes = (line) => {
          return props.wordnotes.filter(wordnote => wordnote.line === line.line_no);
        }
        const filteredAnnotationNotes = (line) => {
          return props.annotationnotes.filter(annotationnote => annotationnote.line === line.line_no);
        }
        return {filteredWordNotes, filteredAnnotationNotes}
      },
        methods: {
            hasWordNote (line) {
                return this.wordnotes.find(Wordnote => {
                    return Wordnote.line === line.line_no;
                })
            },
            hasAnnotationNote (line) {
                return this.annotationnotes.find(AnnotationNote => {
                    return AnnotationNote.line === line.line_no;
                })
            },
            hasNote (line) {
                return this.hasWordNote(line) || this.hasAnnotationNote(line);
            }
        }
    }
</script>

<style scoped>
    .reader__notes__line-container {
        /*display: inline;*/
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .reader__notes__line-container .btn-dialog-small {
        width: 0;
        color: #fff;
        /*display: inline;*/
        font-size: 0.85rem;
        transition: width 0.3s ease-in-out;
    }

    .reader__notes__line-container:hover .btn-dialog-small {
        /*display: inline;*/
        width: 0.85rem;
        color: #ddd;
    }

    .reader__notes__line-container:hover .btn-dialog-small:hover {
        color: #326470;
    }


    .reader__notes__annotation-reference, .reader__notes__note-lemma {
        display: inline;
    }

    .reader__notes__note-text {
        display: inline;
        line-break: loose;
        word-wrap: break-word;
        white-space: nowrap;
        word-break: break-all;
        overflow: hidden;
    }

    .reader__notes__note-divider {
        margin: 0 0.5rem;
    }

    .reader__highlight {
        text-shadow: 0px 0px 3px rgba(150, 150, 150, 0.5);
    }

    .reader__notes__btn-view-full, .reader__notes__btn-view-normal {
        display: none;
        margin: 0;
        padding: 0;
        height: 3rem;
        width: 3rem;
        position: absolute;
        color: #75868a;
        right: 0;
        top: 0;
        background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 20%, rgba(255, 255, 255, 0.85) 100%);
        background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 20%, rgba(255, 255, 255, 0.85) 100%);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.85) 20%, rgba(255, 255, 255, 0.85) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00ffffff', endColorstr='#ffffff', GradientType=1);
    }

    .reader__notes__btn-view-full:hover, .reader__notes__btn-view-normal:hover {
        color: #326470;
    }

    .note-height__full {
        height: auto !important;
    }

    @media only screen and (min-width: 960px) {
        .reader__notes__line-container {
            font-size: 1rem;
        }
    }

    @media only screen and (min-width: 960px) {
        .reader__notes__line-container {
            font-size: 0.85rem;
        }
    }
</style>