<template>
  <div v-show="this.active" class="reader__workspace__analysis--sentence__toolbar--item answers w-full p-4">
    Die Musterlösung des Satzes
  </div>
</template>

<script>
import {EventBus} from "../../../../hermeneus.eventbus/eventbus";

export default {
  name: "reader-sentence-viewer-answers",
  components: {},
  props: {name: {required: true}},
  data: function () {
    return {
      active: false,
      btn_img_src: '/img/svg/hermeneus-btn-answers.svg',
      btn_hover_classes: '',
      btn_disabled_if: !this.$parent.$parent.$parent.AnswersAreEnabled || !this.$parent.$parent.$parent.hasAnswers || !this.$parent.$parent.Answer,
      btn_hidden_if: !this.$parent.$parent.$parent.hasAnswers || !this.$parent.$parent.Answer,
      btn_infobar_html: `Übersetzung/Musterlösung anzeigen. Dies muss vorher in der Textkonfiguration aktiviert werden!`,
      btn_html: `Musterlösung`
    }

  },

  methods: {
    activate() {
      EventBus.$emit('toggleSentenceViewerAnswerMode', true)

    },
    deactivate() {
      EventBus.$emit('toggleSentenceViewerAnswerMode', false)
    }
  },

}
</script>

<style scoped>

</style>